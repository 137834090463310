import {Box} from './Box';
import {Typography} from '@mui/material'
import Logo from '../images/logo-cli.webp';

function Footer({open}) {

    return (
        <Box
            component="footer"
            className="flex items-center justify-center mt-auto py-4 px-2"
            sx={{borderTop: '1px solid rgba(0, 0, 0, 0.12)',}}
            open={open}
        >
            <Typography variant="body2" className="mr-2" color="primary.dark">
                Desenvolvido por
            </Typography>
            <a
                href="https://www.clisolucoes.com.br/"
                target="_blank"
                rel="noreferrer"
            >
                <img
                    src={Logo}
                    alt="Cli Soluções e Confiabilidade"
                />
            </a>
        </Box>
    );
}

export default Footer;

export {Footer};
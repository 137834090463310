import {TableLubricators} from '../components/TableLubricators';
import {useEffect} from 'react';
import {useTitle} from '../hooks';

function ListLubricators() {
    const {setTitle} = useTitle();

    useEffect(() => setTitle('Lubrificadores'), [setTitle]);

    return <TableLubricators/>;
}

export default ListLubricators;

export {ListLubricators};
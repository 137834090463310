import {api} from '../services';
import {useEffect, useState} from 'react';
import {Autocomplete, Grid, TextField} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AddIcon from '@mui/icons-material/Add';

function FindFolder({folder, setFolder}) {
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timeoutSearch, setTimeoutSearch] = useState(null);

    const handleDisableSubmit = event => event.keyCode === 13 && event.preventDefault();

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch);
        setTimeoutSearch(setTimeout(() => setQuery(search), 1000));
    };

    useEffect(() => {
        if (!query) {
            let newOptions = [];

            if (folder) {
                newOptions = [folder];
            }

            setOptions(newOptions);
            return undefined;
        }

        setLoading(true);

        api
            .get('/folders', {
                params: {
                    query,
                }
            })
            .then(response => {
                let newOptions = [];

                if (query.length >= 3) {
                    newOptions = [{name: query}];
                }

                if (response.data) {
                    newOptions = [...newOptions, ...response.data.data];
                }

                setOptions(newOptions);
            })
            .finally(() => setLoading(false));
    }, [folder, query]);

    return (
        <Autocomplete
            autoComplete
            includeInputInList
            value={folder}
            options={options}
            loading={loading}
            loadingText="Buscando ..."
            noOptionsText={query ? 'Nenhuma pasta encontrada' : 'Digite o nome ou id da pasta'}
            filterOptions={x => x}
            getOptionLabel={option => option?.id ? option.name : `Criar "${option.name}"`}
            onInputChange={(event, value) => setSearch(value)}
            onChange={(event, value) => {
                setQuery('');
                setFolder(value);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Pasta"
                    fullWidth
                    onKeyUp={handleTimeoutSearch}
                    onKeyDown={handleDisableSubmit}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={props.key}>
                    <Grid container alignItems="center">
                        <Grid item sx={{display: 'flex', width: 44}}>
                            {option?.id ? (option.is_root ? <CorporateFareIcon/> : <FolderIcon/>) : <AddIcon/>}
                        </Grid>
                        <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                            {option?.id ? option.name : `Criar pasta "${option.name}"`}
                        </Grid>
                    </Grid>
                </li>
            )}
        />
    );
}

export default FindFolder;

export {FindFolder};
function getNodeIds(folders) {
    const ids = [];

    function getFolderIds(folder) {
        if (folder?.children?.length !== 0 || (typeof folder?.machines === 'object' && folder?.machines?.length !== 0)) {
            ids.push(`folder-${folder.id}`);
        }

        if (folder.children) {
            Object.entries(folder.children).forEach(([i, child]) => {
                getFolderIds(child);
            });
        }

        if (folder.machines) {
            Object.entries(folder.machines).forEach(([i, machine]) => {
                getMachineIds(machine);
            });
        }
    }

    function getMachineIds(machine) {
        if ((typeof machine?.subsets === 'object' && machine?.subsets?.length !== 0) || (typeof machine?.lubricators === 'object' && machine?.lubricators?.length !== 0)) {
            ids.push(`machine-${machine.id}`);
        }

        if (machine.subsets) {
            Object.entries(machine.subsets).forEach(([i, subset]) => {
                getSubsetIds(subset);
            });
        }
    }

    function getSubsetIds(subset) {
        if ((typeof subset?.components === 'object' && subset?.components?.length !== 0) || (typeof subset?.lubricators === 'object' && subset?.lubricators?.length !== 0)) {
            ids.push(`subset-${subset.id}`);
        }

        if (subset.components) {
            Object.entries(subset.components).forEach(([i, component]) => {
                getComponentIds(component);
            });
        }
    }

    function getComponentIds(component) {
        if (typeof component?.lubricators === 'object' && component?.lubricators?.length !== 0) {
            ids.push(`component-${component.id}`);
        }
    }

    Object.entries(folders).map(([i, folder]) => getFolderIds(folder));

    return ids;
}

export default getNodeIds;

export {getNodeIds};
import {ListItem as ListItemMUI, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';

function ListItem({text, Icon, route, regEx, onClick = () => null}) {
    const local = useLocation();
    const regex = new RegExp(regEx ?? route);

    return (
        <Link
            to={route}
            style={{textDecoration: 'none', color: 'inherit'}}
            onClick={onClick}
        >
            <ListItemMUI
                key={text}
                disablePadding
            >
                <ListItemButton
                    selected={regex.test(local.pathname)}
                >
                    <ListItemIcon>
                        <Icon/>
                    </ListItemIcon>
                    <ListItemText primary={text}/>
                </ListItemButton>
            </ListItemMUI>
        </Link>
    );
}

export default ListItem;

export {ListItem};
import {useEffect, useState} from 'react';
import {api} from '../services';
import {useSearchParams} from 'react-router-dom';
import {Box, CircularProgress, Grid, TextField} from '@mui/material';
import {Alert} from '../components';
import {LockReset} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {useTitle} from '../hooks/useTitle';

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({});
    const [valid, setValid] = useState(null);
    const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);
    const [errors, setErrors] = useState({});
    const {setTitle} = useTitle();
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const handleChange = () => errors ? setErrors({}) : null;

    useEffect(() => setTitle('Redefinir Senha'), [setTitle]);

    useEffect(() => {
        function setInvalid() {
            setValid(false);
            setAlert({
                severity: 'error',
                title: 'Link inválido.',
                message: 'O link para redefinição de senha é inválido ou expirou.',
            });
        }

        api
            .get('/reset-password', {
                params: {
                    token,
                    email
                }
            })
            .then(response => {
                if (!response?.data?.valid) setInvalid();
                else setValid(true);
            })
            .catch(setInvalid)
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    function handleSubmit(event) {
        event.preventDefault();

        setDisableBtnSubmit(true);

        const password = event.target?.password?.value;
        const password_confirmation = event.target?.password_confirmation?.value;

        if (password !== password_confirmation) {
            setErrors({
                password: 'As senhas não conferem.',
                password_confirmation: 'As senhas não conferem.',
            });
            setDisableBtnSubmit(false);
            return;
        }

        api
            .post('/reset-password', {
                token,
                email,
                password,
                password_confirmation,
            })
            .then(response => {
                setAlert({
                    severity: 'success',
                    title: response?.data?.title,
                    message: response?.data?.message,
                });
                setValid(null);
            })
            .catch(error => {
                if (error.response?.status === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else setAlert({
                    severity: 'error',
                    title: 'Erro ao redefinir senha.',
                    message: 'Não foi possível redefinir a senha, tente novamente mais tarde.',
                });
            })
            .finally(() => setDisableBtnSubmit(false));
    }


    if (loading) return <CircularProgress className="m-auto"/>;

    return (
        <Box className="mx-auto">
            <Alert
                {...alert}
                className="mb-3 py-10"
            />
            {
                valid === true && (
                    <Grid
                        component="form"
                        onSubmit={handleSubmit}
                        maxWidth="sm"
                        className="p-2"
                    >
                        <TextField
                            id="email"
                            label="E-mail"
                            variant="standard"
                            margin="normal"
                            type="email"
                            fullWidth
                            disabled
                            value={email}
                            autoComplete="username"
                        />
                        <TextField
                            id="password"
                            label="Nova senha"
                            variant="standard"
                            margin="normal"
                            type="password"
                            onChange={handleChange}
                            fullWidth
                            required
                            error={Boolean(errors?.password)}
                            helperText={errors?.password}
                            autoComplete="new-password"
                        />
                        <TextField
                            id="password_confirmation"
                            label="Confirme a nova senha"
                            variant="standard"
                            margin="normal"
                            type="password"
                            onChange={handleChange}
                            fullWidth
                            required
                            error={Boolean(errors?.password_confirmation)}
                            helperText={errors?.password_confirmation}
                            autoComplete="new-password"
                        />
                        <LoadingButton
                            id="btn-submit"
                            variant="contained"
                            fullWidth
                            startIcon={<LockReset/>}
                            type="submit"
                            loading={disableBtnSubmit}
                            disabled={Object.keys(errors).length > 0}
                            className="mt-3"
                        >
                            Trocar Senha
                        </LoadingButton>
                    </Grid>
                )
            }
        </Box>
    );
}

export default ResetPassword;

export {ResetPassword};
import {createContext, useState} from 'react';
import env from 'react-dotenv';

const TitleContext = createContext({});

function TitleProvider({children}) {
    const [title, setTitle] = useState(env?.APP_TITLE);
    const setDocumentTitle = title => document.title = title + ' | ' + env?.APP_TITLE;

    const setAllTitles = (title, documentTitle) => {
        if (!documentTitle) documentTitle = title;

        setTitle(title);
        setDocumentTitle(documentTitle);
    }

    return (
        <TitleContext.Provider value={{title, setTitle: setAllTitles}}>
            {children}
        </TitleContext.Provider>
    );
}

export {TitleContext, TitleProvider};
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import BtnCancel from './BtnCancel';
import {LoadingButton} from '@mui/lab';
import LinkIcon from '@mui/icons-material/Link';
import {useContext, useEffect, useState} from 'react';
import {Alert, FindNewLubricators} from './';
import {filterObject, getAssetAppearance} from '../utils';
import {AlertContext, api, TreeContext} from '../services';
import useAuth from '../hooks/useAuth';

function AssociateLubricator() {
    const {handleLogout} = useAuth();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [lubricator, setLubricator] = useState(null);
    const [appearance, setAppearance] = useState({});
    const {associateLubricator, setAssociateLubricator, loadAssetTree} = useContext(TreeContext);
    const {newAlert} = useContext(AlertContext);

    function clearErrors(key) {
        if (errors[key]) {
            setErrors(filterObject(errors, index => index !== key));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        api
            .patch(`/lubricators/${lubricator.id}/associate`, {
                parent_id: associateLubricator.id,
                parent_type: associateLubricator.type,
            })
            .then(response => {
                newAlert(response?.data?.message, 'success');
                setAssociateLubricator(false);
                loadAssetTree(`${associateLubricator.type}-${associateLubricator.id}`);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else newAlert(error.response?.data?.message ?? `Erro ao tentar associar lubrificador. Tente novamente mais tarde.`);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        setAppearance(getAssetAppearance(associateLubricator?.type ?? ''));
        setLubricator(null);
        // eslint-disable-next-line
    }, [associateLubricator]);

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={Boolean(associateLubricator?.id)}
            onClose={() => setAssociateLubricator(false)}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                Associar a{appearance.prefix === 'o' && 'o'} {appearance.type}
            </DialogTitle>
            <DialogContent dividers>
                <BtnCancel onClick={() => setAssociateLubricator(false)}/>
                <Alert
                    severity="error"
                    className="flex justify-center mb-2"
                    message={errors?.lubricator}
                />
                <FindNewLubricators
                    lubricator={lubricator}
                    setLubricator={setLubricator}
                    handleChange={() => clearErrors('lubricator')}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    color="success"
                    startIcon={<LinkIcon/>}
                    disabled={Object.keys(errors).length > 0}
                    fullWidth
                    type="submit"
                    loading={loading}
                >
                    Associar Lubrificador
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AssociateLubricator;

export {AssociateLubricator};
import {ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {useContext} from 'react';
import {TreeContext} from '../services';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import useAuth from '../hooks/useAuth';

function MenuEditAsset() {
    const {user, can} = useAuth();
    const {menu, setMenu, setManageAsset, setShowConfirmDelete, setListUsers} = useContext(TreeContext);
    const {type, root, id} = menu?.data ?? {};
    const isRoot = type === 'folder' && root === parseInt(id);

    return (
        <Menu
            id="menu-edit-asset"
            anchorEl={menu?.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={menu?.type === 'edit'}
            onClose={() => setMenu(false)}
        >
            {
                can(isRoot ? 'rename_parent_folder' : 'update_subfolders_assets', root) && (
                    <MenuItem
                        onClick={() => {
                            setMenu(false);
                            setManageAsset(menu?.data);
                        }}
                    >
                        <ListItemIcon>
                            <EditIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Renomear
                        </ListItemText>
                    </MenuItem>
                )
            }
            {
                type === 'folder' && can('manage_user_folder', root) && (
                    <MenuItem
                        onClick={() => {
                            setMenu(false);
                            setListUsers(menu?.data);
                        }}
                    >
                        <ListItemIcon>
                            <GroupIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Usuários
                        </ListItemText>
                    </MenuItem>
                )
            }
            {
                ((!isRoot && can('delete_subfolders_assets', root)) || user?.is_admin) && (
                    <MenuItem
                        onClick={() => {
                            setMenu(false);
                            setShowConfirmDelete(menu?.data);
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Deletar
                        </ListItemText>
                    </MenuItem>
                )
            }
        </Menu>
    );
}

export default MenuEditAsset;

export {MenuEditAsset};
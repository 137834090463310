import {
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem as ListItemMUI,
    ListSubheader,
    Tooltip,
    Typography,
} from '@mui/material';
import {DrawerHeader, ListItem, SelectWorkspace} from './';
import {useAuth} from '../hooks/useAuth';
import {useEffect, useState} from 'react';
import env from 'react-dotenv';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountTreeIcon from '@mui/icons-material/AccountTreeOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import Logo from '../images/menu.webp';
import {Link} from 'react-router-dom';

function Aside({isMobile, open, openMobile, handleDrawerClose, handleDrawerTransitionEnd}) {
    const {user, workspace, workspaces} = useAuth();
    const [selectWorkspace, setSelectWorkspace] = useState(false);

    const handleSelectToggle = () => setSelectWorkspace(!selectWorkspace);
    const handleClick = () => isMobile ? handleDrawerClose() : null;

    // eslint-disable-next-line
    useEffect(() => handleDrawerClose, []);

    return (
        <>
            <SelectWorkspace
                selectWorkspace={selectWorkspace}
                setSelectWorkspace={setSelectWorkspace}
            />
            <Box
                component="aside"
                sx={{width: 280, flexShrink: 0}}
                aria-label="menu"
            >
                <Drawer
                    variant={isMobile ? 'temporary' : 'persistent'}
                    open={isMobile ? openMobile : open}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    sx={{
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 280,
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    <DrawerHeader>
                        <Link
                            to="/"
                            className="grow flex justify-center align-center"
                        >
                            <img
                                src={Logo}
                                alt={env?.APP_TITLE}
                                height={35}
                            />
                        </Link>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>
                    <List>
                        <ListSubheader
                            component="div"
                            className="font-bold"
                        >
                            Área de Trabalho
                        </ListSubheader>
                        <ListItemMUI
                            key={0}
                            disablePadding
                        >
                            <Tooltip title="Selecionar área de Trabalho">
                                <Button
                                    variant="text"
                                    className="flex items-end p-2"
                                    sx={{
                                        textTransform: 'none',
                                        color: 'inherit',
                                        width: '100%',
                                    }}
                                    onClick={handleSelectToggle}
                                >
                                    <Typography
                                        component="div"
                                        variant="body1"
                                        className="grow p-2"
                                        sx={{
                                            border: '1px solid #DFE3E8',
                                            borderRight: 'none',
                                        }}
                                        align="left"
                                    >
                                        {workspaces[workspace]}
                                    </Typography>
                                    <Box
                                        className="flex-none flex items-center"
                                        sx={{border: '1px solid #DFE3E8'}}
                                    >
                                        <FilterListIcon className="m-2"/>
                                    </Box>
                                </Button>
                            </Tooltip>
                        </ListItemMUI>
                    </List>
                    <List>
                        <ListSubheader
                            component="div"
                            className="font-bold"
                        >
                            Dados
                        </ListSubheader>
                        <ListItem
                            text="Dashboard"
                            Icon={HomeIcon}
                            route="/dashboard"
                            onClick={handleClick}
                        />
                        <ListItem
                            text="Árvore de Ativos"
                            Icon={AccountTreeIcon}
                            route="/asset-tree"
                            onClick={handleClick}
                        />
                        <ListItem
                            text="Lubrificadores"
                            Icon={WaterDropIcon}
                            route="/lubricators"
                            regEx="^/lubricators/?[0-9]*$"
                            onClick={handleClick}
                        />
                    </List>
                    {
                        user?.is_admin && (
                            <List>
                                <ListSubheader
                                    component="div"
                                    className="font-bold"
                                >
                                    Gerenciar
                                </ListSubheader>
                                <ListItem
                                    text="Usuários"
                                    Icon={GroupIcon}
                                    route="/users"
                                    onClick={handleClick}
                                />
                                <ListItem
                                    text="Permissões"
                                    Icon={LockOpenIcon}
                                    route="/roles"
                                    onClick={handleClick}
                                />
                                <ListItem
                                    text="Lubrificadores"
                                    Icon={WaterDropIcon}
                                    route="/lubricators/manage"
                                    onClick={handleClick}
                                />
                            </List>
                        )
                    }
                </Drawer>
            </Box>
        </>
    );
}

export default Aside;

export {Aside};
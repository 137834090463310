function filterObject(object, filter) {
    const keys = Object.keys(object);

    return keys.filter(key => filter(key))
        .reduce((result, key) => {
            result[key] = object[key]

            return result;
        }, {});
}

export default filterObject;

export {filterObject};
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Send} from '@mui/icons-material';
import {api} from '../services';
import {useState} from 'react';
import Alert from './Alert';
import BtnCancel from './BtnCancel';

function ForgotPassword({forgotPassword, setForgotPassword, email, handleChange, errors, setErrors, disabled = false}) {
    const theme = useTheme();
    const [disableBtn, setDisableBtn] = useState(false);
    const [alert, setAlert] = useState({});
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setAlert({});
        setForgotPassword(false);
    };

    function handleSubmit(event) {
        event.preventDefault();

        setDisableBtn(true);
        setAlert({});

        api
            .post('/forgot-password', {email})
            .then(response => {
                setAlert({
                    severity: 'success',
                    title: response?.data?.title,
                    message: response?.data?.message,
                })
            })
            .catch(error => {
                if (error.response?.status === 422) {
                    const {errors} = error.response?.data || {errors: {}};

                    setErrors(errors);
                } else {
                    setAlert({
                        severity: 'error',
                        title: error.response?.data?.title ?? 'Erro ao enviar e-mail.',
                        message: error.response?.data?.message ?? 'Não foi possível enviar o e-mail, tente novamente mais tarde.',
                    })
                }
            })
            .finally(() => setDisableBtn(false));
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={forgotPassword}
            onClose={handleClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                Redefinição de senha
            </DialogTitle>
            <DialogContent dividers>
                <BtnCancel onClick={handleClose}/>
                <Alert
                    severity={alert?.severity}
                    title={alert?.title}
                    message={alert?.message}
                    onClose={() => setAlert()}
                />
                <TextField
                    id="email"
                    label="E-mail"
                    variant="standard"
                    margin="normal"
                    type="email"
                    required
                    fullWidth
                    autoFocus
                    onChange={handleChange}
                    value={email}
                    error={Boolean(errors?.email)}
                    helperText={errors?.email}
                    disabled={disabled}
                    autoComplete="username"
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    fullWidth
                    startIcon={<Send/>}
                    type="submit"
                    loading={disableBtn}
                    disabled={Boolean(errors?.email)}
                >
                    Enviar e-mail
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ForgotPassword;

export {ForgotPassword};
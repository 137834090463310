import {Box, Button, Divider, Drawer, IconButton, Tooltip, Typography, useTheme} from '@mui/material';
import {TreeView} from '@mui/x-tree-view';
import {Folders} from './tree';
import {useEffect, useState} from 'react';
import getNodeIds from '../utils/getNodeIds';
import {useAuth} from '../hooks';
import DrawerHeader from './DrawerHeader';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

function SelectWorkspace({selectWorkspace, setSelectWorkspace}) {
    const {setWorkspace, treeWorkspaces} = useAuth();
    const theme = useTheme();
    const handleExpandAll = () => setExpanded(allNodes.length === expanded.length ? [] : allNodes);
    const handleSelectClose = () => setSelectWorkspace(false);
    const [allNodes, setAllNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const handleGoTo = id => {
        setWorkspace(id);
        handleSelectClose();
    };

    useEffect(() => setAllNodes(getNodeIds(treeWorkspaces)), [treeWorkspaces]);

    return (
        <Box
            component="aside"
            aria-label="menu"
        >
            <Drawer
                variant="temporary"
                open={selectWorkspace}
                onClose={handleSelectClose}
                anchor="right"
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        [theme.breakpoints.up('sm')]: {
                            width: 600,
                        },
                        [theme.breakpoints.up('lg')]: {
                            width: 900,
                        },
                        width: '100%',
                        boxSizing: 'border-box',
                    },
                }}
            >
                <DrawerHeader>
                    <Typography
                        color="inherit"
                        sx={{
                            flexGrow: 1,
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        Selecione a Área de Trabalho
                    </Typography>
                    <Tooltip title="Cancelar">
                        <IconButton onClick={handleSelectClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                </DrawerHeader>
                <Divider/>
                <Box
                    className="text-end p-3"
                    sx={{width: '100%'}}
                >
                    <Button
                        variant="contained"
                        startIcon={allNodes.length === expanded.length ? <UnfoldLessIcon/> : <UnfoldMoreIcon/>}
                        onClick={handleExpandAll}
                        disabled={allNodes.length === 0}
                    >
                        {allNodes.length === expanded.length ? 'Recolher' : 'Expandir'}
                    </Button>
                </Box>
                <Box
                    className="p-3"
                    sx={{width: '100%'}}
                >
                    <TreeView
                        disableSelection
                        aria-label="Áreas de Trabalho"
                        defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon/>}
                        defaultExpandIcon={<AddBoxOutlinedIcon/>}
                        defaultEndIcon={<DisabledByDefaultOutlinedIcon className="opacity-30"/>}
                        expanded={expanded}
                        onNodeToggle={(event, nodes) => setExpanded(nodes)}
                        sx={{
                            width: '100%',
                            overflow: 'auto',
                        }}
                    >
                        <Folders
                            folders={treeWorkspaces}
                            goTo={handleGoTo}
                            icon={HomeIcon}
                        />
                    </TreeView>
                </Box>
            </Drawer>
        </Box>
    );
}

export default SelectWorkspace;

export {SelectWorkspace};
import {useEffect, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {CssBaseline, useMediaQuery, useTheme} from '@mui/material';
import {NotificationsProvider, TitleProvider, TreeProvider} from './services';
import {
    AssetTree,
    CreateOrEditUser,
    Dashboard,
    DetailsLubricator,
    ListLubricators,
    Login,
    ManageLubricators,
    MyAccount,
    Notifications,
    ResetPassword,
    Roles,
    Users,
    VerifyEmail
} from './routes';
import {DialogNotifications, Footer, GoToDashboard, Header, ListAlerts, Main, VerifyEmailAlert} from './components';
import {RequireAdmin, RequireAuth, RequireGuest} from './components/auth';

function RouteList() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => isMobile ? setOpenMobile(!openMobile) : setOpen(!open);
    const handleDrawerClose = () => isMobile ? setOpenMobile(false) : setOpen(false);

    useEffect(() => {
        if (isMobile) setOpen(false);
    }, [isMobile]);

    return (
        <BrowserRouter>
            <CssBaseline/>
            <TitleProvider>
                <NotificationsProvider>
                    <Header
                        open={open}
                        openMobile={openMobile}
                        handleDrawerToggle={handleDrawerToggle}
                        handleDrawerClose={handleDrawerClose}
                        isMobile={isMobile}
                    />
                    <VerifyEmailAlert open={open}/>
                    <Main
                        open={open}
                        className="flex items-center flex-wrap md:p-6 p-4"
                    >
                        <Routes>
                            <Route index path="/" element={
                                <Navigate to="/login"/>
                            }/>
                            <Route path="/login" element={
                                <RequireGuest>
                                    <Login/>
                                </RequireGuest>
                            }/>
                            <Route path="/my-account" element={
                                <RequireAuth>
                                    <MyAccount/>
                                </RequireAuth>
                            }/>
                            <Route path="/notifications" element={
                                <RequireAuth>
                                    <Notifications/>
                                </RequireAuth>
                            }/>
                            <Route path="/dashboard" element={
                                <RequireAuth>
                                    <Dashboard/>
                                </RequireAuth>
                            }/>
                            <Route path="/asset-tree" element={
                                <RequireAuth>
                                    <TreeProvider>
                                        <AssetTree/>
                                    </TreeProvider>
                                </RequireAuth>
                            }/>
                            <Route path="/lubricators" element={
                                <RequireAuth>
                                    <ListLubricators/>
                                </RequireAuth>
                            }/>
                            <Route path="/roles" element={
                                <RequireAuth>
                                    <RequireAdmin>
                                        <Roles/>
                                    </RequireAdmin>
                                </RequireAuth>
                            }/>
                            <Route path="/lubricators/install" element={
                                <RequireAuth>
                                </RequireAuth>
                            }/>
                            <Route path="/lubricators/manage" element={
                                <RequireAuth>
                                    <RequireAdmin>
                                        <ManageLubricators/>
                                    </RequireAdmin>
                                </RequireAuth>
                            }/>
                            <Route path="/lubricators/:id" element={
                                <RequireAuth>
                                    <TreeProvider>
                                        <DetailsLubricator/>
                                    </TreeProvider>
                                </RequireAuth>
                            }/>
                            <Route path="/users" element={
                                <RequireAuth>
                                    <RequireAdmin>
                                        <Users/>
                                    </RequireAdmin>
                                </RequireAuth>
                            }/>
                            <Route path="/users/new" element={
                                <RequireAuth>
                                    <RequireAdmin>
                                        <CreateOrEditUser/>
                                    </RequireAdmin>
                                </RequireAuth>
                            }/>
                            <Route path="/users/:user_id/edit" element={
                                <RequireAuth>
                                    <RequireAdmin>
                                        <CreateOrEditUser/>
                                    </RequireAdmin>
                                </RequireAuth>
                            }/>
                            <Route path="/reset-password" element={<ResetPassword/>}/>
                            <Route path="/email/verify/:user_id/:email" element={
                                <RequireAuth>
                                    <VerifyEmail/>
                                </RequireAuth>
                            }/>
                        </Routes>
                        <GoToDashboard/>
                    </Main>
                    <DialogNotifications open={open}/>
                </NotificationsProvider>
            </TitleProvider>
            <ListAlerts/>
            <Footer open={open}/>
        </BrowserRouter>
    );
}

export default RouteList;

export {RouteList};
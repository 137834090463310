import {Grid, IconButton, Typography} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

function RefreshedAt({hour, className, refresh = () => null,}) {
    return hour ? (
        <Grid
            container
            className={className}
            justifyContent="end"
        >
            <Typography>
                Atualizado às {hour}
                <IconButton onClick={refresh}>
                    <RefreshIcon/>
                </IconButton>
            </Typography>
        </Grid>
    ) : null;
}

export default RefreshedAt;

export {RefreshedAt};
import {TreeView} from '@mui/x-tree-view';
import {Folders} from '../components/tree';
import {Box, Button, CircularProgress} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {TreeContext} from '../services';
import getNodeIds from '../utils/getNodeIds';
import {useAuth, useTitle} from '../hooks';
import {
    ConfirmDeleteAsset,
    ConfirmDisassociateLubricator,
    CreateOrEditAsset,
    DuplicateAsset,
    ListUserRole,
    MenuEditAsset,
    MenuNewAsset,
    RefreshedAt,
} from '../components';
import HomeIcon from '@mui/icons-material/Home';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

function AssetTree() {
    const {workspace, emptyPermissions} = useAuth();
    const {loadAssetTree, folder, isConfiguring, setIsConfiguring, expanded, setExpanded} = useContext(TreeContext);
    const [loading, setLoading] = useState(true);
    const [allNodes, setAllNodes] = useState([]);
    const {setTitle} = useTitle();
    const handleExpandAll = () => setExpanded(allNodes.length === expanded.length ? [] : allNodes);

    useEffect(() => setTitle('Árvore', 'Árvore de Ativos'), [setTitle]);

    useEffect(() => setAllNodes(getNodeIds(folder)), [folder]);

    useEffect(() => {
        setLoading(true);

        loadAssetTree(null, () => setLoading(false));
        // eslint-disable-next-line
    }, [workspace]);

    if (loading) return <CircularProgress className="m-auto"/>;

    return (
        <>
            <Box className="self-start sm:text-end text-center" sx={{width: '100%'}}>
                <Button
                    variant="contained"
                    startIcon={allNodes.length === expanded.length ? <UnfoldLessIcon/> : <UnfoldMoreIcon/>}
                    onClick={handleExpandAll}
                    className="me-2 sm:me-3"
                    disabled={allNodes.length === 0}
                >
                    {allNodes.length === expanded.length ? 'Recolher' : 'Expandir'}
                </Button>
                {
                    !emptyPermissions() && (
                        <Button
                            variant="contained"
                            startIcon={isConfiguring ? <VisibilityIcon/> : <SettingsIcon/>}
                            onClick={() => setIsConfiguring(!isConfiguring)}
                            disabled={allNodes.length === 0}
                        >
                            {isConfiguring ? 'Visualizar' : 'Gerenciar'}
                        </Button>
                    )
                }
                <RefreshedAt
                    className="self-start mt-3 mb-2"
                    hour={folder.data.hour}
                    refresh={loadAssetTree}
                />
            </Box>
            <Box className="self-start" sx={{width: '100%'}}>
                <TreeView
                    disableSelection
                    aria-label="Árvore de ativos"
                    defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon/>}
                    defaultExpandIcon={<AddBoxOutlinedIcon/>}
                    defaultEndIcon={<DisabledByDefaultOutlinedIcon className="opacity-30"/>}
                    expanded={expanded}
                    onNodeToggle={(event, nodes) => setExpanded(nodes)}
                    sx={{
                        width: '100%',
                        overflow: 'auto',
                    }}
                >
                    {
                        Object.keys(folder).length !== 0 && (
                            <Folders
                                folders={folder}
                                icon={HomeIcon}
                            />
                        )
                    }
                </TreeView>
            </Box>
            <MenuNewAsset/>
            <ListUserRole/>
            <MenuEditAsset/>
            <DuplicateAsset/>
            <ConfirmDeleteAsset/>
            <CreateOrEditAsset/>
            <ConfirmDisassociateLubricator callback={loadAssetTree}/>
        </>
    );
}

export default AssetTree;

export {AssetTree};
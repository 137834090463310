import {CircularProgress} from '@mui/material';
import {Navigate} from 'react-router-dom';
import {useAuth} from '../../hooks/useAuth';

function RequireGuest({children}) {
    const {loading, authenticated} = useAuth();

    if (loading) return <CircularProgress className="m-auto"/>;

    if (authenticated) return <Navigate to="/dashboard"/>;

    return children;
}

export default RequireGuest;

export {RequireGuest};
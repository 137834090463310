import {createContext} from 'react';
import {initializeApp} from 'firebase/app';
import {getMessaging} from 'firebase/messaging';
import env from 'react-dotenv';

const FirebaseContext = createContext({});

function FirebaseProvider({children}) {
    const firebaseConfig = {
        apiKey: env.API_KEY_FIREBASE,
        projectId: env.PROJECT_ID_FIREBASE,
        messagingSenderId: env.MESSAGING_SENDER_ID_FIREBASE,
        appId: env.APP_ID_FIREBASE,
    };

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app)

    return (
        <FirebaseContext.Provider value={{app, messaging}}>
            {children}
        </FirebaseContext.Provider>
    );
}

export {FirebaseContext, FirebaseProvider};
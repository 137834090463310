import {createContext, useState} from 'react';

const AlertContext = createContext({});

function AlertsProvider({children}) {
    const [alerts, setAlerts] = useState([]);
    const newAlert = (message, type = 'error') => setAlerts(alerts => [...alerts, {type, message}]);

    return (
        <AlertContext.Provider value={{alerts, setAlerts, newAlert}}>
            {children}
        </AlertContext.Provider>
    );
}

export {AlertContext, AlertsProvider};
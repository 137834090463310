import {Link} from 'react-router-dom';
import StyledTreeItem from './StyledTreeItem';
import WaterDropIcon from '@mui/icons-material/WaterDrop';

function Lubricator({lubricator, root}) {
    return (
        <Link
            to={`/lubricators/` + lubricator.id}
            style={{textDecoration: 'none'}}
        >
            <StyledTreeItem
                nodeId={`lubricator-${lubricator.id}`}
                name={`Lubrificador #${lubricator.id}`}
                status={lubricator.status}
                icon={WaterDropIcon}
                root={root}
            />
        </Link>
    );
}

function Lubricators({lubricators, root}) {
    if (!lubricators) return null;

    return (
        Object.entries(lubricators).map(([i, lubricator]) => (
            <Lubricator
                lubricator={lubricator}
                key={lubricator.id}
                root={root}
            />
        ))
    );
}

export default Lubricators;

export {Lubricators};
import {useEffect} from 'react';
import {TableLubricators} from '../components';
import useTitle from '../hooks/useTitle';

function ManageLubricators() {
    const {setTitle} = useTitle();

    useEffect(() => setTitle('Lubrificador', 'Gerenciar Lubrificadores'), [setTitle]);

    return <TableLubricators manage={true}/>;
}

export default ManageLubricators;

export {ManageLubricators};
import {useContext, useEffect, useState} from 'react';
import {useAuth, useTitle} from '../hooks';
import {
    Alert,
    Box,
    Breadcrumbs,
    Chip,
    CircularProgress,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {ListSubareas, RefreshedAt} from '../components';
import {AlertContext, api} from '../services';
import {LineChart, PieChart} from '@mui/x-charts';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

function Dashboard() {
    const theme = useTheme();
    const {setTitle} = useTitle();
    const {workspace, setWorkspace, handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(true);
    const [dashboard, setDashboard] = useState({});
    const [open, setOpen] = useState(false);
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('lg', 99999));
    const [health, setHealth] = useState(null);
    const [dates, setDates] = useState([]);
    const icon = {
        null: <HomeIcon sx={{mr: 0.5}} fontSize="small"/>,
        true: <CorporateFareIcon sx={{mr: 0.5}} fontSize="small"/>,
        false: <FolderIcon sx={{mr: 0.5}} fontSize="small"/>,
    };

    function handleClick(id) {
        setWorkspace(id);
        setOpen(false);
    }

    function loadDashboard() {
        setLoading(true)

        api
            .get('/dashboard', {
                params: {
                    workspace,
                }
            })
            .then(response => setDashboard(response.data?.data))
            .catch(error => {
                if (error.response?.status === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao carregar o dashboard. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Dashboard'), [setTitle]);

    // eslint-disable-next-line
    useEffect(loadDashboard, [workspace]);

    useEffect(() => {
        if (!('lubricators' in dashboard)) return;

        setDates(() => {
            let dates = [];

            for (let i = 6; i >= 0; i--) {
                const date = new Date();

                date.setDate(date.getDate() - i);

                dates.push(date.getDate() + '/' + (date.getMonth() + 1))
            }

            return dates;
        })
        
        if (!dashboard.lubricators) {
            setHealth(null);
            return;
        }

        const percentage = (dashboard.lubricators - dashboard.alerts.errors) / dashboard.lubricators;

        setHealth((Math.ceil(percentage * 10000) / 100).toLocaleString());
    }, [dashboard]);

    if (loading) return <CircularProgress className="m-auto"/>;

    return (
        <Box
            className="self-start"
            sx={{width: '100%'}}
        >
            <Typography variant="caption">
                Você está em:
            </Typography>
            <Box className="mt-2" sx={{width: '100%'}}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon sx={{marginBottom: '12px'}}/>}
                >
                    {
                        dashboard.location.map((folder, i) => {
                            const Item = props => (
                                <Chip
                                    {...props}
                                    icon={icon[folder.is_root]}
                                    label={folder.name}
                                    className="mb-3"
                                    onClick={() => setWorkspace(folder.id)}
                                />
                            );

                            if (Object.entries(dashboard.options).length > 0 && dashboard.location.length - 1 === i) {
                                return (
                                    <div key={i} className="flex items-start">
                                        <Item/>&nbsp;&nbsp;
                                        <Tooltip title="Trocar">
                                            <IconButton
                                                onClick={() => setOpen(true)}
                                                aria-label="Trocar"
                                            >
                                                <SwapHorizIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                );
                            }

                            return <Item key={i}/>;
                        })
                    }
                </Breadcrumbs>
            </Box>
            <Grid
                container
                spacing={2}
            >
                <RefreshedAt
                    className="self-start mt-3"
                    hour={dashboard.hour}
                    refresh={loadDashboard}
                />
                {
                    !dashboard.lubricators && (
                        <Grid item xs={12}>
                            <Alert severity="warning">
                                Essa área não possui lubrificadores associados.
                            </Alert>
                        </Grid>
                    )
                }
                <Grid
                    item
                    xs={12}
                    lg={6}
                    className="flex justify-center items-center flex-col min-[1200px]:block"
                >
                    <Typography className="font-bold">
                        Saúde Total
                    </Typography>
                    <PieChart
                        width={isLargeScreen ? 500 : 320}
                        height={isLargeScreen ? 320 : 400}
                        colors={['#FF5E5E', '#63FF55']}
                        series={[
                            {
                                data: [
                                    {
                                        label: 'Falhas',
                                        value: dashboard.alerts.errors
                                    },
                                    {
                                        label: 'Normal',
                                        value: dashboard.lubricators - dashboard.alerts.errors,
                                    },
                                ],
                                innerRadius: 72,
                                outerRadius: 104,
                                paddingAngle: 0,
                                cornerRadius: 0,
                                startAngle: 0,
                                endAngle: 360,
                                cx: 150,
                                cy: 150,
                            }
                        ]}
                        slotProps={{
                            legend: {
                                direction: 'column',
                                position: {
                                    vertical: isLargeScreen ? 'middle' : 'bottom',
                                    horizontal: isLargeScreen ? 'right' : 'middle',
                                },
                            }
                        }}
                    >
                        <text x={130} y={156} className="text-xl break-words">
                            Saúde
                        </text>
                        <text
                            x={150.5 - (health?.length ?? 0) * 5.5 + (String(health).includes(',') ? 2.6 : 0)}
                            y={178}
                            className="text-xl break-words"
                        >
                            {
                                health === null ?
                                    '-' :
                                    health + '%'
                            }
                        </text>
                    </PieChart>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <LineChart
                        height={300}
                        series={[
                            {
                                type: 'line',
                                color: '#FF5E5E',
                                label: 'Falhas',
                                data: dashboard.history.errors,
                            },
                        ]}
                        slotProps={{legend: {hidden: true}}}
                        {
                            ...(dates.length !== 0 && {xAxis: [{scaleType: 'point', data: dates}]})
                        }
                    />
                </Grid>
            </Grid>
            <ListSubareas subareas={dashboard.areas}/>
            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogTitle>
                    Trocar área de trabalho
                </DialogTitle>
                <List disablePadding>
                    {
                        Object.entries(dashboard.options).map(([i, folder]) => (
                            <ListItem disablePadding key={folder.id}>
                                <ListItemButton onClick={() => handleClick(folder.id)}>
                                    <ListItemIcon>
                                        {
                                            folder.id_root ?
                                                <CorporateFareIcon/> :
                                                <FolderIcon/>
                                        }
                                    </ListItemIcon>
                                    <ListItemText primary={folder.name}/>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </List>
            </Dialog>
        </Box>
    );
}

export default Dashboard;

export {Dashboard};
import {CardActions, CardContent, Checkbox, FormControlLabel, Grid, Link, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useState} from 'react';
import {api} from '../services';
import useAuth from '../hooks/useAuth';
import BtnShowPassword from './BtnShowPassword';
import {filterObject} from '../utils/filterObject';

function UpdateAccount({newAlert}) {
    const {handleLogout, user, setUser, setLoadingBackdrop} = useAuth();
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [disableUpdateAccountBtn, setDisableUpdateAccountBtn] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);

    function handleChange(event) {
        if (event.target.id === 'name') setName(event.target.value);
        else if (event.target.id === 'email') setEmail(event.target.value);
        else setPassword(event.target.value);

        if (errors[event.target.id]) {
            setErrors(filterObject(errors, index => index !== event.target.id));
        }
    }

    function handleCancelChange() {
        setLoadingBackdrop(true);

        api.patch('/email/cancel-change')
            .then(response => {
                setUser(user => {
                    let newUser = {...user};

                    newUser.new_email = null;

                    return newUser
                });

                newAlert(response?.data?.message, 'success');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar cancelar alteração. Tente novamente mais tarde.');
                }
            })
            .finally(setLoadingBackdrop(false));
    }

    function handleSubmit(event) {
        event.preventDefault();

        let data = {};

        setDisableUpdateAccountBtn(true);

        if (changeEmail) {
            data = {
                name,
                email,
                password
            };
        } else {
            data = {name};
        }

        api.patch('/update-account', data)
            .then(response => {
                newAlert(response?.data?.message, 'success');

                setUser(user => {
                    let newUser = {...user};

                    newUser.name = name;
                    if (changeEmail) newUser.new_email = email;

                    return newUser
                });
                setEmail(user.email)
                setPassword('');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar atualizar informações. Tente novamente mais tarde.');
                }
            })
            .finally(() => setDisableUpdateAccountBtn(false));
    }

    return (
        <Grid
            component="form"
            className="p-2 mx-auto mb-auto"
            onSubmit={handleSubmit}
        >
            <CardContent>
                <TextField
                    id="name"
                    label="Nome"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    value={name}
                    onChange={handleChange}
                    required
                    fullWidth
                    autoFocus
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                    autoComplete="name"
                />
                <TextField
                    id="email"
                    label="E-mail"
                    variant="outlined"
                    margin="normal"
                    type="email"
                    value={email}
                    onChange={handleChange}
                    fullWidth
                    autoFocus
                    disabled={!changeEmail}
                    error={Boolean(errors?.email)}
                    helperText={
                        errors?.email ?? (
                            user?.new_email ?
                                (
                                    <>
                                        O e-mail {user?.new_email} está pendente de confirmação.&nbsp;
                                        <Link
                                            color="error"
                                            underline="none"
                                            component="button"
                                            type="button"
                                            onClick={handleCancelChange}
                                        >
                                            Cancelar alteração
                                        </Link>
                                    </>
                                ) :
                                null
                        )
                    }
                    autoComplete="username"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={changeEmail}
                            id="change-mail"
                            onChange={event => setChangeEmail(event.target.value)}
                        />
                    }
                    label="Editar Email"
                />
                {
                    changeEmail && (
                        <TextField
                            name="password"
                            label="Senha atual"
                            variant="outlined"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            required
                            value={password}
                            onChange={handleChange}
                            error={Boolean(errors?.password)}
                            helperText={errors?.password}
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                            }}
                        />
                    )
                }
            </CardContent>
            <CardActions className="p-3">
                <LoadingButton
                    variant="contained"
                    disabled={name.length < 3}
                    type="submit"
                    loading={disableUpdateAccountBtn}
                    fullWidth
                >
                    Salvar informações
                </LoadingButton>
            </CardActions>
        </Grid>
    );
}

export default UpdateAccount;

export {UpdateAccount};
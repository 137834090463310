import {
    Button,
    CardContent,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api} from '../services';
import TabletIcon from '@mui/icons-material/Tablet';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TvIcon from '@mui/icons-material/Tv';
import ComputerIcon from '@mui/icons-material/Computer';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import DeleteIcon from '@mui/icons-material/Delete';
import {useAuth} from '../hooks';

function getIcon(name) {
    if (name.indexOf('Tablet') !== -1) return TabletIcon;
    if (name.indexOf('Smartphone') !== -1) return SmartphoneIcon;
    if (name.indexOf('Smart TV') !== -1) return TvIcon;
    if (name.indexOf('Desktop') !== -1) return ComputerIcon;
    return DeviceUnknownIcon;
}

function ActiveSessions() {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loadingEnd, setLoadingEnd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState([]);

    const generateItems = (sessions, action = true) => (
        <List>
            {
                sessions.map(session => {
                    const Icon = getIcon(session.name);

                    return (
                        <ListItem
                            key={session?.id}
                            secondaryAction={
                                !action ?
                                    <Typography
                                        variant="caption"
                                        component="span"
                                    >
                                        Atual
                                    </Typography>
                                    :
                                    loadingEnd ?
                                        <CircularProgress size={20}/>
                                        :
                                        <IconButton
                                            edge="end"
                                            aria-label="Emcerrar"
                                            onClick={() => endSession(session?.id)}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                            }
                        >
                            <ListItemIcon>
                                <Icon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={session?.name}
                                secondary={
                                    <>
                                        Criada em: {session?.created_at}
                                        <br/>
                                        Último acesso: {session?.last_used_at ?? '-'}
                                    </>
                                }
                            />
                        </ListItem>
                    );
                })
            }
        </List>
    );

    function endSession(id) {
        setLoadingEnd(true);

        api
            .delete('/sessions/' + id)
            .then(response => {
                newAlert(response?.data?.message, 'success');

                setSessions(sessions => {
                    const newSessions = {...sessions};

                    newSessions.data.others = newSessions.data.others.filter(session => session.id !== id);

                    return newSessions;
                });
            })
            .catch(error => {
                if (error.response?.status === 401) handleLogout();
                else newAlert('Não foi possível encerrar essa sessão. Tente novamente mais tarde.')
            })
            .finally(() => setLoadingEnd(false))
    }

    function endAllSessions() {
        setLoadingEnd(true);

        api
            .delete('/sessions')
            .then(response => {

                newAlert(response?.data?.message, 'success');

                setSessions(sessions => {
                    const newSessions = {...sessions};

                    newSessions.data.others = [];

                    return newSessions;
                });
            })
            .catch(error => {
                if (error.response?.status === 401) handleLogout();
                else newAlert('Não foi possível encerrar as sessões. Tente novamente mais tarde.')
            })
            .finally(() => setLoadingEnd(false))
    }

    useEffect(() => {
        api.get('/sessions')
            .then(response => setSessions(response?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert('Erro ao tentar carregar as sessões ativas. Tente novamente mais tarde.');

                setSessions({});
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return (
            <CardContent className="text-center">
                <CircularProgress className="mt-5 mb-3"/>
            </CardContent>
        );
    }

    return (
        <CardContent className="text-center">
            {generateItems([sessions.data.current], false)}
            <Button
                variant="contained"
                color="error"
                className="my-3"
                disabled={loadingEnd || sessions.data.others.length === 0}
                onClick={endAllSessions}
            >
                Encerrar todas as outras sessões ativas
            </Button>
            {generateItems(sessions.data.others)}
        </CardContent>
    );

}

export default ActiveSessions;

export {ActiveSessions};
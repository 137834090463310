import {Box} from '@mui/material';
import LoginForm from '../components/LoginForm';
import {useEffect} from 'react';
import {useTitle} from '../hooks';

function Login() {
    const {setTitle} = useTitle();

    useEffect(() => setTitle('Login'), [setTitle]);

    return (
        <Box className="m-auto">
            <LoginForm/>
        </Box>
    );
}

export default Login;

export {Login};
import {Box} from '@mui/material'
import {Link, useLocation} from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function GoToDashboard() {
    const local = useLocation();

    if (local.pathname === '/dashboard' || local.pathname === '/login') return;

    return (
        <Box
            className="self-end mt-3"
            sx={{width: '100%'}}
        >
            <Link
                to="/dashboard"
                className="flex items-center justify-center no-underline"
                style={{color: '#0874BB'}}
            >
                <span>Ir para o Dashboard</span>
                <ArrowForwardIcon className="ms-2"/>
            </Link>
        </Box>
    );
}

export default GoToDashboard;

export {GoToDashboard};
import {
    Alert,
    Box,
    CircularProgress,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Typography
} from '@mui/material';
import {blue} from '@mui/material/colors';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import {useNotifications} from '../hooks/useNotifications';

function ListNotifications({close = true}) {
    const {
        loading,
        notifications,
        handleCloseNotifications,
        readAll,
        handleClickNotification,
    } = useNotifications();

    const colors = {
        green: '#63FF55',
        red: '#FF5E5E',
    };

    return (
        <>
            <ListItem key="-1" sx={{pb: 2, pt: 1}}>
                <Box
                    className="flex"
                    sx={{
                        width: '100%',
                        justifyContent: close ? 'space-between' : 'end',
                    }}
                >
                    {
                        notifications?.meta?.unread >= 1 && !loading && (
                            <Link
                                underline="none"
                                component="button"
                                variant="body2"
                                onClick={readAll}
                            >
                                Ler tudo
                            </Link>
                        )
                    }
                    {
                        close && (
                            <Link
                                underline="none"
                                component="button"
                                variant="body2"
                                onClick={handleCloseNotifications}
                            >
                                Fechar
                            </Link>
                        )
                    }
                </Box>
            </ListItem>
            {
                loading && (
                    <ListItem
                        key={0}
                        className="justify-center py-9"
                        sx={{
                            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                            minWidth: '300px',
                        }}
                    >
                        <CircularProgress/>
                    </ListItem>
                )
            }
            {
                !loading && notifications?.data?.map((notification, i) => {
                    const read = Boolean(notification?.read_at);
                    const color = colors[notification?.data?.color] || colors.red;
                    const Icon = WaterDropIcon;

                    return (
                        <MenuItem
                            selected={!read}
                            key={notification?.id}
                            className="items-center py-2 px-2"
                            sx={{borderTop: '1px solid rgba(0, 0, 0, 0.12)'}}
                            onClick={() => handleClickNotification(notification, i)}
                        >
                            <ListItemIcon>
                                <Icon sx={{color}}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={notification?.data?.title}
                                secondary={
                                    <>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="text.secondary"
                                            className="text-wrap"
                                        >
                                            {notification?.data?.content}
                                        </Typography>
                                        <br/>
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            sx={{color: blue[700]}}
                                        >
                                            {notification?.created_at}
                                        </Typography>
                                    </>
                                }
                            />
                        </MenuItem>
                    )
                })
            }
            {
                !loading && notifications?.data?.length === 0 && (
                    <ListItem
                        key={0}
                        className="justify-center py-9"
                        sx={{
                            minWidth: '300px',
                            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        }}
                    >
                        <Alert severity="warning">
                            Sem notificações
                        </Alert>
                    </ListItem>
                )
            }
        </>
    );
}

export default ListNotifications;

export {ListNotifications};
import {IconButton, InputAdornment} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';

const BtnShowPassword = ({setShow, show}) => (
    <InputAdornment
        position="end"
        className="me-2"
    >
        <IconButton
            aria-label="Mudar visibilidade da senha"
            onClick={() => setShow(!show)}
            edge="end"
        >
            {show ? <VisibilityOff/> : <Visibility/>}
        </IconButton>
    </InputAdornment>
);

export default BtnShowPassword;

export {BtnShowPassword};
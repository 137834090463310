import StyledTreeItem from './StyledTreeItem';
import {Lubricators, Subsets} from './';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

function Machine({machine, root}) {
    if (machine?.lubricators?.length === 0 && machine?.subsets?.length === 0) {
        return (
            <StyledTreeItem
                nodeId={`machine-${machine.id}`}
                name={machine.name}
                icon={PrecisionManufacturingIcon}
                status={machine.status}
                root={root}
            />
        );
    }

    return (
        <StyledTreeItem
            nodeId={`machine-${machine.id}`}
            name={machine.name}
            icon={PrecisionManufacturingIcon}
            status={machine.status}
            root={root}
        >
            <Subsets
                subsets={machine?.subsets}
                root={root}
            />
            <Lubricators
                lubricators={machine?.lubricators}
                root={root}
            />
        </StyledTreeItem>
    );
}

function Machines({machines, root}) {
    if (!machines) return null;

    return (
        Object.entries(machines).map(([i, machine]) => (
            <Machine
                machine={machine}
                key={machine.id}
                root={root}
            />
        ))
    );
}

export default Machines;

export {Machines};
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api, TreeContext} from '../services';
import useAuth from '../hooks/useAuth';
import getAssetAppearance from '../utils/getAssetAppearance';

function ConfirmDeleteAsset() {
    const {handleLogout} = useAuth();
    const theme = useTheme();
    const {newAlert} = useContext(AlertContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {loadAssetTree, showConfirmDelete, setShowConfirmDelete} = useContext(TreeContext);
    const [appearance, setAppearance] = useState({});

    function deleteAsset() {
        api
            .delete(`/${showConfirmDelete?.type}s/${showConfirmDelete?.id}`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                loadAssetTree();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? `Erro ao tentar excluir ${appearance.prefix} ${appearance.type}. Tente novamente mais tarde.`);
            })
            .finally(() => setShowConfirmDelete(false));
    }

    useEffect(() => {
        setAppearance(getAssetAppearance(showConfirmDelete?.type ?? ''));
        // eslint-disable-next-line
    }, [showConfirmDelete]);

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={Boolean(showConfirmDelete?.id)}
            onClose={() => setShowConfirmDelete(false)}
            fullScreen={fullScreen}
        >
            <DialogTitle>
                Excluir {appearance.prefix} {appearance.type} <span
                className="font-bold">{showConfirmDelete?.name}</span>?
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    A exclusão dess{appearance.prefix === 'o' ? 'e' : 'a'} {appearance.type} excluirá todos seus
                    subitens, com exceção dos lubrificadores que serão desassociados apenas.
                </DialogContentText>
                <div className="text-center font-bold mt-3">
                    ESTA AÇÃO NÃO PODERÁ SER DESFEITA!
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    color="inherit"
                    onClick={() => setShowConfirmDelete(false)}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={deleteAsset}
                    variant="contained"
                    color="error"
                >
                    Deletar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDeleteAsset;

export {ConfirmDeleteAsset};
import {Box, IconButton, Tooltip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function BtnCancel({onClick}) {
    return (
        <Box textAlign="right">
            <Tooltip title="Cancelar">
                <IconButton
                    size="large"
                    onClick={onClick}
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 8,
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default BtnCancel;

export {BtnCancel};
import {Alert as AlertMUI, AlertTitle} from '@mui/material';

const Alert = ({title, message, ...props}) => (
    message && (
        <AlertMUI {...props}>
            {
                title && (
                    <AlertTitle>
                        {title}
                    </AlertTitle>
                )
            }
            {message}
        </AlertMUI>
    )
);

export default Alert;

export {Alert};
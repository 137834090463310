import {useAuth, useTitle} from '../hooks';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import {Alert, BtnShowPassword, FindFolder} from '../components';
import {LoadingButton} from '@mui/lab';
import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {filterObject} from '../utils/filterObject';
import {useParams} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';

function CreateOrEditUser() {
    const {setTitle} = useTitle();
    const {handleLogout, setLoadingBackdrop} = useAuth();
    const {user_id: userId} = useParams();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([{id: 0, name: 'Carregando ...'}]);
    const [folders, setFolders] = useState([]);
    const [role, setRole] = useState([]);
    const [count, setCount] = useState(1);

    function handleChange(event, index = null) {
        if (event.target.name === 'name') setName(event.target.value);
        else if (event.target.name === 'email') setEmail(event.target.value);
        else if (event.target.name === 'role') {
            setRole(role => {
                const newRole = {...role};

                newRole[index] = event.target.value;

                return newRole;
            });
        }

        setErrors(filterObject(errors, index => index !== event.target.name));
    }

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        const password = event?.target?.password?.value;
        const password_confirmation = event?.target?.password_confirmation?.value;

        let apiMethod = api.post;
        let apiEndpoint = '/users';

        if (userId) {
            apiMethod = api.put;
            apiEndpoint += '/' + userId;
        }

        apiMethod(apiEndpoint, {
            name,
            email,
            password,
            password_confirmation,
            is_admin: isAdmin,
            folders,
            role,
        })
            .then(response => {
                newAlert(response.data?.message, 'success');

                event.target.reset();

                if (!userId) {
                    setName('');
                    setEmail('');
                    setFolders([]);
                    setRole([]);
                }
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else newAlert(error.response?.data?.message ?? 'Erro ao criar o usuário. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
    }

    function handleSetFolder(i, value) {
        setFolders(folders => {
            const newFolders = {...folders};

            newFolders[i] = value;

            return newFolders
        })
    }

    // eslint-disable-next-line
    useEffect(() => setTitle(!userId ? 'Novo usuário' : 'Editar usuário'), []);

    useEffect(() => {
        api.get('/roles/all')
            .then(response => setRoles(response.data?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao carregar as funções. Tente novamente mais tarde.');
            })
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (!userId) return;

        setLoadingBackdrop(true);

        api.get('/users/' + userId)
            .then(response => {
                response = response.data?.data;

                setName(response?.name ?? '');
                setEmail(response?.email ?? '');
                setIsAdmin(Boolean(response?.is_admin));

                if (!Boolean(response?.is_admin)) {
                    setCount(response?.folders?.length ?? 1);
                    setFolders(response?.folders ?? []);
                    setRole(response?.roles ?? []);
                }
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar o usuário. Tente novamente mais tarde.');
                }
            })
            .finally(setLoadingBackdrop(false))
        // eslint-disable-next-line
    }, [userId]);

    return (
        <Grid
            container
            component="form"
            className="self-start"
            sx={{width: '100%'}}
            onSubmit={handleSubmit}
            spacing={2}
        >
            <Grid item xs={12}>
                <Typography variant="h5">
                    Detalhes da Conta
                </Typography>
                <Divider className="mt-2"/>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="name"
                    label="Nome"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    value={name}
                    onChange={handleChange}
                    required
                    fullWidth
                    autoFocus
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                    autoComplete="name"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="email"
                    label="E-mail"
                    variant="outlined"
                    margin="normal"
                    type="email"
                    value={email}
                    onChange={handleChange}
                    required
                    fullWidth
                    autoFocus
                    error={Boolean(errors?.email)}
                    helperText={errors?.email}
                    autoComplete="username"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="password"
                    label="Senha"
                    variant="outlined"
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    required={Boolean(!userId)}
                    onChange={handleChange}
                    error={Boolean(errors?.new_password)}
                    helperText={errors?.new_password}
                    autoComplete="new-password"
                    InputProps={{
                        endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="password_confirmation"
                    label="Confirme a senha"
                    variant="outlined"
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    required={Boolean(!userId)}
                    onChange={handleChange}
                    error={Boolean(errors?.new_password_confirmation)}
                    helperText={errors?.new_password_confirmation}
                    autoComplete="new-password"
                    InputProps={{
                        endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                className="flex justify-center"
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isAdmin}
                            onChange={event => setIsAdmin(event.target.checked)}
                        />
                    }
                    label="É administrador"
                />
            </Grid>
            {
                !isAdmin && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Áreas de trabalho
                            </Typography>
                            <Divider className="mt-2"/>
                        </Grid>
                        {
                            [...Array(count)].map((x, i) => (
                                <Grid item xs={12} key={i}>
                                    <Grid
                                        container
                                        className="mb-2"
                                        spacing={2}
                                    >
                                        <Grid item xs={12} md={6}>
                                            <FindFolder
                                                folder={folders[i] ?? null}
                                                setFolder={value => handleSetFolder(i, value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Alert
                                                severity="error"
                                                className="flex justify-center mb-2"
                                                mmessage={errors[`role.${i}`] ?? ''}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id={`select-associate-folder-label-${i}`}>Função</InputLabel>
                                                <Select
                                                    labelId={`select-associate-folder-label-${i}`}
                                                    id={`select-associate-folder-${i}`}
                                                    name="role"
                                                    value={role[i] ?? ''}
                                                    label="Função"
                                                    onChange={event => handleChange(event, i)}
                                                >
                                                    {
                                                        roles.map(role => (
                                                            <MenuItem
                                                                key={role.id}
                                                                value={role.id}
                                                            >
                                                                {role.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                        <Grid item xs={12}>
                            <Grid
                                container
                                justifyContent="center"
                                className="mb-2"
                            >
                                <IconButton
                                    color="error"
                                    aria-label="Remover a última pasta"
                                    onClick={() => setCount(count - 1)}
                                    disabled={count === 1}
                                >
                                    <RemoveIcon/>
                                </IconButton>
                                <IconButton
                                    color="success"
                                    aria-label="Adicionar mais um pasta"
                                    onClick={() => setCount(count + 1)}
                                >
                                    <AddIcon/>
                                </IconButton>
                            </Grid>
                            <Divider/>
                        </Grid>
                    </>
                )
            }
            <Grid item className="mx-auto">
                <LoadingButton
                    variant="contained"
                    startIcon={userId ? <EditIcon/> : <AddIcon/>}
                    disabled={Object.keys(errors).length > 0}
                    fullWidth
                    type="submit"
                    loading={loading}
                >
                    {userId ? 'Editar' : 'Criar'} usuário
                </LoadingButton>
            </Grid>
        </Grid>
    );
}

export default CreateOrEditUser;

export {CreateOrEditUser};
import Lubricators from './Lubricators';
import StyledTreeItem from './StyledTreeItem';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

function Component({component, root}) {
    if (component?.lubricators?.length === 0) {
        return (
            <StyledTreeItem
                nodeId={`component-${component.id}`}
                name={component.name}
                icon={RadioButtonCheckedIcon}
                status={component.status}
                root={root}
            />
        );
    }

    return (
        <StyledTreeItem
            nodeId={`component-${component.id}`}
            name={component.name}
            icon={RadioButtonCheckedIcon}
            status={component.status}
            root={root}
        >
            <Lubricators
                lubricators={component?.lubricators}
                root={root}
            />
        </StyledTreeItem>
    );
}

function Components({components, root}) {
    if (!components) return null;

    return (
        Object.entries(components).map(([i, component]) => (
            <Component
                component={component}
                key={component.id}
                root={root}
            />
        ))
    );
}

export default Components;

export {Components};
import Alert from './Alert'

const ActiveAlerts = ({activeAlerts}) => (
    <>
        {
            !activeAlerts?.length && (
                <Alert
                    severity="success"
                    title="Tudo em ordem!"
                    message="Lubrificador sem alertas ativos, tudo funcionando perfeitamente."
                />
            )
        }
        {
            (activeAlerts && activeAlerts.map(alert => {
                let severity;
                let title;
                let message;

                switch (alert.type) {
                    case 'error':
                        severity = 'error';
                        title = 'Falha identificada!';
                        message = 'O lubrificador comunicou uma falha em ' + alert.started_at;
                        break;
                    case 'offline':
                        severity = 'error';
                        title = 'Lubrificador não responde!';
                        message = 'Estamos sem comunicação com esse lubrificador desde ' + alert.started_at;
                        break;
                    default:
                        severity = 'warning';
                        title = 'Possível falha!';
                        message = 'O lubrificador comunicou uma possível falha em ' + alert.started_at;
                }

                return (
                    <Alert
                        severity={severity}
                        title={title}
                        message={message}
                        key={alert.id}
                        className="mb-3"
                    />
                );
            }))
        }
    </>
)

export default ActiveAlerts;

export {ActiveAlerts};
import {useContext} from 'react';
import {AlertContext} from '../services';
import {Alert, Snackbar} from '@mui/material';

function ListAlerts() {
    const {alerts, setAlerts} = useContext(AlertContext);

    return alerts.map((alert, i) => {
        function handleClose() {
            const newAlert = [...alerts];
            newAlert.splice(i, 1);
            setAlerts(newAlert);
        }

        return (
            <Snackbar
                open={true}
                autoHideDuration={5000}
                onClose={handleClose}
                key={i}
            >
                <Alert
                    onClose={handleClose}
                    severity={alert.type}
                    variant="filled"
                >
                    {alert.message}
                </Alert>
            </Snackbar>
        );
    });
}

export default ListAlerts;

export {ListAlerts};
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {useAuth} from '../hooks';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api} from '../services';
import {BtnCancel, ConfirmDisassociateUser, StyledTableCell, StyledTableRow, TablePagination} from './';
import FolderIcon from '@mui/icons-material/Folder';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DeleteIcon from '@mui/icons-material/Delete';

function ListUserFolder({showUsers, setShowUsers}) {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const {newAlert} = useContext(AlertContext);
    const [data, setData] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [confirmDisassociate, setConfirmDisassociate] = useState(false);

    useEffect(() => {
        if (!showUsers) return;

        setLoading(true);

        api
            .get(`/roles/${showUsers.id}/users`, {
                params: {
                    page: page + 1,
                    per_page: perPage,
                },
            })
            .then(response => setData(response.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar as pastas o usuário. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [showUsers, page, perPage]);

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={Boolean(showUsers?.id)}
                onClose={() => setShowUsers(false)}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    Usuários da função <span className="font-bold">{showUsers?.name}</span>
                </DialogTitle>
                <DialogContent dividers>
                    <BtnCancel onClick={() => setShowUsers(false)}/>
                    <TableContainer component={Paper}>
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            sx={{minWidth: 250}}
                            size="small"
                        >
                            <TableHead>
                                <TableRow>
                                    {
                                        ['Nome', 'E-mail', 'Pasta', 'Ação'].map(text => (
                                            <StyledTableCell align="center" key={text}>
                                                {text}
                                            </StyledTableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    loading ? (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <CircularProgress className="m-auto my-3"/>
                                                </TableCell>
                                            </TableRow>
                                        ) :
                                        (
                                            (data && data?.data?.map(({user, folder}, i) => (
                                                <StyledTableRow key={user?.id}>
                                                    <StyledTableCell align="center">
                                                        {user?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {user?.email}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <div className="flex items-center justify-center">
                                                            {
                                                                folder?.is_root ?
                                                                    <CorporateFareIcon/> :
                                                                    <FolderIcon/>
                                                            }
                                                            <span className="ms-2">
                                                                {folder?.name}
                                                            </span>
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Tooltip title="Desassociar">
                                                            <IconButton
                                                                aria-label="Desassociar"
                                                                color="error"
                                                                onClick={() => {
                                                                    setConfirmDisassociate({
                                                                        index: i,
                                                                        folder: {
                                                                            id: folder?.id,
                                                                            name: folder?.name,
                                                                        },
                                                                        user: {
                                                                            id: user?.id,
                                                                            name: user?.name,
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )))
                                        )
                                }
                                {
                                    !loading && !data?.data?.length && (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <Alert severity="warning">
                                                    Nenhum usuário encontrada para essa função.
                                                </Alert>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={4}
                                        rowsPerPage={perPage}
                                        setPerPage={setPerPage}
                                        page={page}
                                        setPage={setPage}
                                        count={data?.meta?.total ?? 0}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => setShowUsers(false)}
                        color="inherit"
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDisassociateUser
                confirmDisassociate={confirmDisassociate}
                setConfirmDisassociate={setConfirmDisassociate}
                setData={setData}
            />
        </>

    );
}

export default ListUserFolder;

export {ListUserFolder};
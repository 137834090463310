import Machines from './Machines';
import StyledTreeItem from './StyledTreeItem';
import FolderIcon from '@mui/icons-material/Folder';

function Folder({folder, icon, goTo, root}) {
    if (!root) root = folder.id;

    if (folder?.children?.length === 0 && (goTo || folder?.machines?.length === 0)) {
        return (
            <StyledTreeItem
                nodeId={`folder-${folder.id}`}
                name={folder.name}
                icon={icon}
                status={folder.status}
                goTo={goTo}
                root={root}
            />
        );
    }

    return (
        <StyledTreeItem
            nodeId={`folder-${folder.id}`}
            name={folder.name}
            icon={icon}
            status={folder.status}
            goTo={goTo}
            root={root}
        >
            <Folders
                folders={folder?.children}
                goTo={goTo}
                root={root}
            />
            {
                !goTo && (
                    <Machines
                        machines={folder?.machines}
                        root={root}
                    />
                )
            }
        </StyledTreeItem>
    );
}

function Folders({folders, goTo, root, icon = FolderIcon}) {
    if (!folders) return null;

    return (
        Object.entries(folders).map(([i, folder]) => (
            <Folder
                folder={folder}
                icon={icon}
                key={folder.id}
                goTo={goTo}
                root={root}
            />
        ))
    );
}

export default Folders;

export {Folders};
import {Components, Lubricators} from './';
import StyledTreeItem from './StyledTreeItem';
import SettingsIcon from '@mui/icons-material/Settings';

function Subset({subset, root}) {
    if (subset?.lubricators?.length === 0 && subset?.components?.length === 0) {
        return (
            <StyledTreeItem
                nodeId={`subset-${subset.id}`}
                name={subset.name}
                icon={SettingsIcon}
                status={subset.status}
                root={root}
            />
        );
    }

    return (
        <StyledTreeItem
            nodeId={`subset-${subset.id}`}
            name={subset.name}
            icon={SettingsIcon}
            status={subset.status}
            root={root}
        >
            <Components
                components={subset?.components}
                root={root}
            />
            <Lubricators
                lubricators={subset?.lubricators}
                root={root}
            />
        </StyledTreeItem>
    );
}

function Subsets({subsets, root}) {
    if (!subsets) return null;

    return (
        Object.entries(subsets).map(([i, subset]) => (
            <Subset
                subset={subset}
                key={subset.id}
                root={root}
            />
        ))
    );
}

export default Subsets;

export {Subsets};
import {useContext, useEffect} from 'react';
import {AlertContext} from '../services';
import {Card, CardHeader, Divider, Grid,} from '@mui/material';
import {ActiveSessions, PasswordChange, UpdateAccount} from '../components';
import {useAuth, useTitle} from '../hooks';

function MyAccount() {
    const {setTitle} = useTitle();
    const {handleLogout, user} = useAuth();
    const {newAlert} = useContext(AlertContext);

    useEffect(() => setTitle('Minha conta'), [setTitle]);

    return (
        <Grid
            container
            className="self-start justify-center"
            spacing={3}
        >
            <Grid item xs={12} md={6}>
                <Card sx={{width: '100%'}}>
                    <CardHeader
                        subheader="Detalhes da conta"
                        subheaderTypographyProps={{color: 'text.primary'}}
                    />
                    <Divider/>
                    <UpdateAccount
                        newAlert={newAlert}
                    />
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card sx={{width: '100%'}}>
                    <CardHeader
                        subheader="Acesso a conta"
                        subheaderTypographyProps={{color: 'text.primary'}}
                    />
                    <Divider/>
                    <PasswordChange
                        newAlert={newAlert}
                        handleLogout={handleLogout}
                        email={user.email}
                    />
                </Card>
            </Grid>
            <Grid item xs={12} md={9} lg={6}>
                <Card sx={{width: '100%'}}>
                    <CardHeader
                        subheader="Sessões ativas"
                        subheaderTypographyProps={{color: 'text.primary'}}
                    />
                    <Divider/>
                    <ActiveSessions/>
                </Card>
            </Grid>
        </Grid>
    );
}

export default MyAccount;

export {MyAccount};
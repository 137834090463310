import {api} from '../services';
import {useEffect, useState} from 'react';
import {Autocomplete, Grid, TextField, Typography} from '@mui/material';
import WaterDropIcon from '@mui/icons-material/WaterDrop';

function FindNewLubricators({lubricator, setLubricator, handleChange}) {
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timeoutSearch, setTimeoutSearch] = useState(null);

    const handleDisableSubmit = event => event.keyCode === 13 && event.preventDefault();

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch);
        setTimeoutSearch(setTimeout(() => setQuery(search), 1000));
    };

    useEffect(() => {
        if (!query) {
            let newOptions = [];

            if (lubricator) {
                newOptions = [lubricator];
            }

            setOptions(newOptions);
            return undefined;
        }

        setLoading(true);

        api
            .get('/lubricators/associate', {
                params: {
                    query,
                }
            })
            .then(response => {
                let newOptions = [];

                if (response.data) {
                    newOptions = [...response.data.data];
                }

                setOptions(newOptions);
            })
            .finally(() => setLoading(false));
    }, [lubricator, query]);

    return (
        <Autocomplete
            autoComplete
            includeInputInList
            value={lubricator}
            options={options}
            loading={loading}
            loadingText="Buscando ..."
            noOptionsText={query ? 'Nenhum lubrificador encontrado' : 'Digite o serial ou id do lubrificador'}
            filterOptions={x => x}
            getOptionLabel={option => `Lubrificador #${option.id}`}
            onInputChange={(event, value) => setSearch(value)}
            onChange={(event, value) => {
                setQuery('');
                setLubricator(value);
                handleChange();
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Lubrificador"
                    fullWidth
                    onKeyUp={handleTimeoutSearch}
                    onKeyDown={handleDisableSubmit}
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid container alignItems="center">
                        <Grid item sx={{display: 'flex', width: 44}}>
                            <WaterDropIcon/>
                        </Grid>
                        <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                            <Grid container direction="column">
                                <Grid item>
                                    Lubrificador #{option.id}
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        {option.serial}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </li>
            )}
        />
    );
}

export default FindNewLubricators;

export {FindNewLubricators};
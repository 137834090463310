import {useContext, useEffect, useState} from 'react';
import {useAuth} from '../hooks';
import {AlertContext, api} from '../services';
import {RefreshedAt, StyledTableCell, StyledTableRow, TablePagination} from './';
import {
    Alert,
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const red = '#FF5E5E';
const green = '#63FF55';

function LatestStatus({lubricator}) {
    const {newAlert} = useContext(AlertContext)
    const {handleLogout} = useAuth();
    const [loading, setLoading] = useState(true);
    const [statuses, setStatuses] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);

    function loadStatuses() {
        setLoading(true);

        api
            .get('/statuses', {
                params: {
                    lubricator: lubricator,
                    page: page + 1,
                    per_page: perPage,
                }
            })
            .then(response => setStatuses(response?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 404) newAlert('O lubrificador solicitado não foi encontrado.');
                else if (code === 403) newAlert('Você não tem permissão para acessar os status desse lubrificador.');
                else newAlert('Erro ao tentar carregar os status desse lubrificador. Tente novamente mais tarde.');

                setStatuses([]);
            })
            .finally(() => setLoading(false));
    }

    // eslint-disable-next-line
    useEffect(loadStatuses, [lubricator, page, perPage]);

    if (loading) return <CircularProgress/>;

    return (
        <>
            <RefreshedAt
                className="self-start my-1"
                hour={statuses.hour}
                refresh={loadStatuses}
            />
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{minWidth: 250}} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Data e Hora</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (statuses && statuses?.data?.map(status => {
                                const iconColor = status?.ok ? green : red;

                                return (
                                    <StyledTableRow key={status?.id}>
                                        <StyledTableCell align="center">
                                            <Box color={iconColor} className="mr-1"
                                                 component={CircleIcon}/>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {status?.datetime}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            }))
                        }
                        {
                            !statuses?.data?.length && (
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        <Alert severity="warning">
                                            Nenhum status encontrado para o lubrificador informado.
                                        </Alert>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={2}
                                rowsPerPage={perPage}
                                setPerPage={setPerPage}
                                page={page}
                                setPage={setPage}
                                count={statuses?.meta?.total}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
}

export default LatestStatus;

export {LatestStatus};
import {useAuth} from '../hooks';
import {AlertContext, api} from '../services';
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField,} from '@mui/material';
import BtnCancel from './BtnCancel';
import {useContext, useEffect, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

function CreateOrEditLubricator({lubricator, onClose, fullScreen, loadLubricators}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [serial, setSerial] = useState('');
    const [loading, setLoading] = useState(false);

    function handleChange(event) {
        setSerial(event.target.value);

        if (errors[event.target.name]) {
            setErrors({});
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        let apiMethod = api.post;
        let apiEndpoint = '/lubricators';

        if (lubricator?.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + lubricator.id;
        }

        setLoading(true);

        apiMethod(apiEndpoint, {serial})
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                loadLubricators();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = lubricator?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} o lubrificador. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setSerial(lubricator?.serial ?? ''), [lubricator]);

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={Boolean(lubricator)}
            onClose={onClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                {lubricator?.id ? 'Editar' : 'Novo'} lubrificador {Boolean(lubricator?.id) && `#${lubricator?.id}`}
            </DialogTitle>
            <DialogContent dividers>
                <BtnCancel onClick={onClose}/>
                <TextField
                    name="serial"
                    label="Nº de série"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    value={serial}
                    onChange={handleChange}
                    fullWidth
                    autoFocus
                    error={Boolean(errors?.serial)}
                    helperText={errors?.serial}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    startIcon={lubricator?.id ? <EditIcon/> : <AddIcon/>}
                    disabled={Boolean(errors?.serial)}
                    fullWidth
                    type="submit"
                    loading={loading}
                >
                    {lubricator?.id ? 'Editar' : 'Criar'} lubrificador
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditLubricator;

export {CreateOrEditLubricator};
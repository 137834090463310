import {createRoot} from 'react-dom/client';
import RouteList from './RouteList';
import reportWebVitals from './reportWebVitals';
import {AlertsProvider, AuthProvider, FirebaseProvider} from './services';
import {CookiesProvider} from 'react-cookie'
import {createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import './styles/index.css';

const root = createRoot(document.getElementById('root'));

let theme = createTheme({
    palette: {
        primary: {
            light: '#398fc8',
            main: '#0874BB',
            dark: '#014882',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Poppins, sans-serif',
    }
});

theme = responsiveFontSizes(theme);

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <FirebaseProvider>
                <AlertsProvider>
                    <AuthProvider>
                        <CookiesProvider>
                            <RouteList/>
                        </CookiesProvider>
                    </AuthProvider>
                </AlertsProvider>
            </FirebaseProvider>
        </ThemeProvider>
    </StyledEngineProvider>
);

reportWebVitals(console.log);
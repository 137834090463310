import {useState} from 'react';
import {Box, Checkbox, FormControlLabel, Grid, Link, TextField} from '@mui/material';
import {Login} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Logo from '../images/logo.webp';
import ForgotPassword from './ForgotPassword';
import Alert from './Alert';
import {useAuth} from '../hooks/useAuth';
import env from 'react-dotenv';
import BtnShowPassword from './BtnShowPassword';
import {filterObject} from '../utils/filterObject';

function LoginForm() {
    const {handleLogin, authenticated} = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [disableBtnLogin, setDisableBtnLogin] = useState(false);
    const [errors, setErrors] = useState({});
    const [forgotPassword, setForgotPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleForgotPasswordClick = () => setForgotPassword(true);
    const [searchParams] = useSearchParams();
    let redirectTo = searchParams.get('redirect');

    function handleChange(event) {
        if (event.target.id === 'email') setEmail(event.target.value);

        if (errors[event.target.id]) {
            setErrors(filterObject(errors, index => index !== event.target.id));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        const password = event.target?.password?.value;
        const remember = event.target?.remember?.checked;

        setDisableBtnLogin(true);

        if (redirectTo) {
            if (!redirectTo.startsWith('/') || redirectTo.includes('http') || redirectTo.includes('javascript')) {
                redirectTo = null;
            }
        }

        const redirect = () => redirectTo ? navigate(redirectTo) : null;

        handleLogin(email, password, remember, setErrors, redirect).then(() => {
            if (!authenticated) setDisableBtnLogin(false);
        });
    }

    return (
        <>
            <Grid
                component="form"
                onSubmit={handleSubmit}
                className="p-2 mx-auto mb-auto"
                maxWidth="sm"
            >
                <Box className="text-center">
                    <img
                        src={Logo}
                        alt={env?.APP_TITLE}
                    />
                </Box>
                {
                    redirectTo && (
                        <Alert
                            severity="warning"
                            title="Autenticação necessária."
                            message="Faça login para acessar a página solicitada."
                            className="mt-2"
                        />
                    )
                }
                <TextField
                    id="email"
                    label="E-mail"
                    variant="standard"
                    margin="normal"
                    type="email"
                    required
                    fullWidth
                    autoFocus
                    onChange={handleChange}
                    value={email}
                    error={Boolean(errors?.email)}
                    helperText={errors?.email}
                    autoComplete="username"
                />
                <TextField
                    id="password"
                    label="Senha"
                    variant="standard"
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    required
                    fullWidth
                    autoFocus
                    onChange={handleChange}
                    error={Boolean(errors?.password)}
                    helperText={errors?.password}
                    autoComplete="current-password"
                    InputProps={{
                        endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                    }}
                />
                <Grid container alignItems="center" justifyContent="space-between" className="mb-3">
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox defaultChecked={true} id="remember"/>}
                            label="Manter conectado"
                        />
                    </Grid>
                    <Grid item textAlign="right">
                        <Link
                            underline="none"
                            onClick={handleForgotPasswordClick}
                            component="button"
                            variant="body2"
                            type="button"
                        >
                            Esqueceu a senha?
                        </Link>
                    </Grid>
                </Grid>
                <LoadingButton
                    id="btn-login"
                    variant="contained"
                    fullWidth
                    startIcon={<Login/>}
                    type="submit"
                    loading={disableBtnLogin}
                    disabled={Object.keys(errors).length > 0}
                >
                    Entrar
                </LoadingButton>
            </Grid>
            <ForgotPassword
                forgotPassword={forgotPassword}
                setForgotPassword={setForgotPassword}
                email={email}
                handleChange={handleChange}
                errors={errors}
                setErrors={setErrors}
            />
        </>
    );

}

export default LoginForm;

export {LoginForm};
import {createContext, useContext, useState} from 'react';
import {AlertContext, api} from './';
import {useAuth} from '../hooks';

const TreeContext = createContext({});

function TreeProvider({children}) {
    const [folder, setFolder] = useState({});
    const [isConfiguring, setIsConfiguring] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const {newAlert} = useContext(AlertContext);
    const {workspace} = useAuth();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [menu, setMenu] = useState(false);
    const [manageAsset, setManageAsset] = useState(false);
    const [duplicateAsset, setDuplicateAsset] = useState(false);
    const [associateLubricator, setAssociateLubricator] = useState(false);
    const [showConfirmDisassociate, setShowConfirmDisassociate] = useState(false);
    const [listUsers, setListUsers] = useState(false);

    function loadAssetTree(addExpanded = null, final = () => null) {
        api
            .get('/assets-tree', {
                params: {
                    workspace,
                }
            })
            .then(response => {
                setFolder(response.data);

                if (addExpanded !== null) {
                    setExpanded(expanded => {
                        const newExpanded = [...expanded];

                        newExpanded.push(addExpanded);

                        return newExpanded;
                    });
                } else if (expanded.length === 0) {
                    setExpanded(Object.entries(response.data).map(([i, folder]) => `folder-${folder?.id}`))
                }
            })
            .catch(() => {
                setFolder([]);
                newAlert('Erro ao tentar carregar árvore de ativos. Tente novamente mais tarde.');
            })
            .finally(final);
    }

    return (
        <TreeContext.Provider
            value={{
                folder,
                setFolder,
                isConfiguring,
                setIsConfiguring,
                manageAsset,
                setManageAsset,
                duplicateAsset,
                setDuplicateAsset,
                loadAssetTree,
                showConfirmDelete,
                setShowConfirmDelete,
                showConfirmDisassociate,
                setShowConfirmDisassociate,
                menu,
                setMenu,
                expanded,
                setExpanded,
                associateLubricator,
                setAssociateLubricator,
                listUsers,
                setListUsers,
            }}
        >
            {children}
        </TreeContext.Provider>
    );
}

export {TreeContext, TreeProvider};
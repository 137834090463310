import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

function getAssetAppearance(type, icon = false) {
    switch (type) {
        case 'folder':
            return {
                ...(icon && {
                    Icon: FolderIcon,
                }),
                type: 'pasta',
                prefix: 'a',
            };
        case 'machine':
            return {
                ...(icon && {
                    Icon: PrecisionManufacturingIcon,
                }),
                type: 'máquina',
                prefix: 'a',
            };
        case 'subset':
            return {
                ...(icon && {
                    Icon: SettingsIcon,
                }),
                type: 'subconjunto',
                prefix: 'o',
            };
        default:
            return {
                ...(icon && {
                    Icon: RadioButtonCheckedIcon,
                }),
                type: 'componente',
                prefix: 'o',
            };
    }
}

export default getAssetAppearance;

export {getAssetAppearance};
import {Link, Typography} from '@mui/material';
import {Alert, Box} from './';
import {api} from '../services/api';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

function VerifyEmailAlert({open}) {
    const {user, setLoadingBackdrop} = useAuth();
    const [alert, setAlert] = useState({});
    const local = useLocation();
    const regex = new RegExp(/\/email\/verify\/\d+\/\w+/);

    if (!user || !user?.new_email || regex.test(local.pathname)) return null;

    function handleResendEmail() {
        setLoadingBackdrop(true);
        setAlert({});

        api.post('/email/resend-verification')
            .then(response => {
                setAlert({
                    severity: 'success',
                    title: response?.data?.title,
                    message: response?.data?.message,
                })
            })
            .catch(() => {
                setAlert({
                    severity: 'error',
                    title: 'Erro ao enviar e-mail.',
                    message: 'Não foi possível enviar o e-mail, tente novamente mais tarde.',
                })
            })
            .finally(() => setLoadingBackdrop(false));
    }

    return (
        <Box open={open}>
            <Alert
                {...alert}
                onClose={() => setAlert({})}
                className="mb-3"
            />
            <Alert
                severity="error"
                title="E-mail não confirmado."
                message={
                    <Typography
                        component="span"
                        variant="inherit"
                        color="inherit"
                    >
                        A alteração do seu e-mail para <span className="font-bold">{user?.new_email}</span> ainda
                        não foi confirmada, clique no link enviado para esse e-mail ou&nbsp;
                        <Link
                            color="inherit"
                            component="button"
                            onClick={handleResendEmail}
                        >
                            solicite um novo link.
                        </Link>
                    </Typography>
                }/>
        </Box>
    );
}

export default VerifyEmailAlert;

export {VerifyEmailAlert};
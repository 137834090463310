import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {useContext, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {AlertContext, api, TreeContext} from '../services';
import useAuth from '../hooks/useAuth';

function ConfirmDisassociateLubricator({callback = () => null}) {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {showConfirmDisassociate, setShowConfirmDisassociate} = useContext(TreeContext);

    function disassociate() {
        setLoading(true);

        api
            .patch(`/lubricators/${showConfirmDisassociate}/disassociate`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                callback();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? `Erro ao tentar desassociar o Lubrificador #${showConfirmDisassociate}. Tente novamente mais tarde.`);
            })
            .finally(() => {
                setLoading(false);
                setShowConfirmDisassociate(false);
            });
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={Boolean(showConfirmDisassociate)}
            onClose={() => setShowConfirmDisassociate(false)}
            fullScreen={fullScreen}
        >
            <DialogTitle>
                Desassociar o Lubrificador <span
                className="font-bold">#{showConfirmDisassociate}</span>?
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Você tem certeza que deseja desassociar esse lubrificador?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    color="inherit"
                    onClick={() => setShowConfirmDisassociate(false)}
                >
                    Cancelar
                </Button>
                <LoadingButton
                    variant="contained"
                    color="error"
                    loading={loading}
                    onClick={disassociate}
                >
                    Desassociar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDisassociateLubricator;

export {ConfirmDisassociateLubricator};
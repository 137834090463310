import {Badge, IconButton, Menu, MenuItem, Tooltip,} from '@mui/material';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {useNotifications} from '../hooks/useNotifications';
import ListNotifications from './ListNotifications';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotificationsIcon from '@mui/icons-material/NotificationsTwoTone';
import {useEffect} from 'react';

function Notifications() {
    const local = useLocation();
    const {
        loading,
        notifications,
        handleShowNotifications,
        handleCloseNotifications,
        showNotifications,
    } = useNotifications();

    // eslint-disable-next-line
    useEffect(() => handleCloseNotifications, []);

    if (local.pathname === '/notifications') return null;

    return (
        <>
            <Tooltip title="Notificações">
                <IconButton
                    size="large"
                    aria-label={
                        notifications?.meta?.unread ?
                            `Veja ${notifications.meta.unread} novas notificações` :
                            'Sem novas notificações'
                    }
                    color="inherit"
                    onClick={handleShowNotifications}
                >
                    <Badge
                        badgeContent={notifications?.meta?.unread}
                        color="error"
                        max={99}
                    >
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-notifications"
                anchorEl={showNotifications}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(showNotifications)}
                onClose={handleCloseNotifications}
            >
                <ListNotifications/>
                {
                    !loading && notifications?.meta?.total > notifications?.meta?.per_page && (
                        <RouterLink
                            to="/notifications"
                            onClick={handleCloseNotifications}
                            style={{
                                textDecoration: 'none',
                                color: 'inherit',
                            }}
                        >
                            <MenuItem
                                key={-2}
                                className="flex items-center justify-center pt-1 pb-0"
                                sx={{borderTop: '1px solid rgba(0, 0, 0, 0.12)'}}
                            >
                                <Tooltip title="Ver todas as notificações">
                                    <MoreHorizIcon/>
                                </Tooltip>
                            </MenuItem>
                        </RouterLink>
                    )
                }
            </Menu>
        </>
    )
}

export default Notifications;

export {Notifications};
const adjustWith = ({theme, open}) => ({
    transitionProperty: 'width, margin-left',
    transitionEasing: theme.transitions.easing.easeOut,
    transitionDuration: theme.transitions.duration.enteringScreen,
    ...(open && {
        width: 'calc(100% - 280px)',
        [theme.breakpoints.not('xs')]: {
            marginLeft: '280px',
        },
    }),
    ...(!open && {
        width: '100%',
    }),
});

export default adjustWith;

export {adjustWith};
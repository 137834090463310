import {useContext, useEffect, useState} from 'react';
import {AlertContext, api} from '../services';
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {Alert, BtnCancel} from './index';
import {LoadingButton} from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import {useAuth} from '../hooks';
import EditIcon from '@mui/icons-material/Edit';
import {filterObject} from '../utils/filterObject';

function CreateOrEditRole({role, onClose, fullScreen, loadRoles}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [checked, setChecked] = useState([]);
    const [loading, setLoading] = useState(false);

    function handleChange(event) {
        if (event.target.name === 'name') setName(event.target.value);
        else if (event.target.name === 'description') setDescription(event.target.value);
        else {
            const permissionId = parseInt(event.target.id.split('-')[1]);

            if (event.target.checked) {
                setChecked([...checked, permissionId]);
            } else {
                setChecked(checked.filter(id => id !== permissionId));
            }
        }

        if (errors[event.target.name]) {
            setErrors(filterObject(errors, index => index !== event.target.name));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        let apiMethod = api.post;
        let apiEndpoint = '/roles';

        if (role?.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + role.id;
        }

        setLoading(true);

        apiMethod(apiEndpoint, {
            name,
            description,
            permissions: checked,
        })
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                loadRoles();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = role?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} a função. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        setName(role?.name ?? '');
        setDescription(role?.description ?? '');
        setChecked(role?.permissions?.map(id => parseInt(id)) ?? []);

        if (role !== false && permissions.length < 1) {
            api.get('/permissions')
                .then(response => setPermissions(response.data?.data))
                .catch(error => {
                    const code = error.response?.status;

                    if (code === 401) handleLogout();
                    else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                    else {
                        newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar as permissões. Tente novamente mais tarde.');
                    }
                });
        }
        // eslint-disable-next-line
    }, [role]);

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={Boolean(role)}
            onClose={onClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                {role?.id ? 'Editar' : 'Nova'} função
            </DialogTitle>
            <DialogContent dividers>
                <BtnCancel onClick={onClose}/>
                <TextField
                    name="name"
                    label="Nome"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    value={name}
                    onChange={handleChange}
                    required
                    fullWidth
                    autoFocus
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                />
                <TextField
                    name="description"
                    label="Descrição"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    value={description}
                    onChange={handleChange}
                    fullWidth
                    autoFocus
                    error={Boolean(errors?.description)}
                    helperText={errors?.description}
                />
                <Typography
                    variant="subtitle1"
                    className="mt-3 mb-1"
                >
                    Permissões
                </Typography>
                <Divider className="mb-3"/>
                <Alert
                    severity="error"
                    message={errors?.permissions}
                />
                <Stack>
                    {
                        permissions.map(permission => (
                            <FormControlLabel
                                label={permission.name}
                                key={permission.id}
                                control={
                                    <Checkbox
                                        id={`permission-${permission.id}`}
                                        name="permissions"
                                        onChange={handleChange}
                                        color={Boolean(errors?.permissions) ? 'error' : 'primary'}
                                        checked={checked.includes(permission.id)}
                                    />
                                }
                            />
                        ))
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    startIcon={role?.id ? <EditIcon/> : <AddIcon/>}
                    disabled={Object.keys(errors).length > 0}
                    fullWidth
                    type="submit"
                    loading={loading}
                >
                    {role?.id ? 'Editar' : 'Criar'} função
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditRole;

export {CreateOrEditRole};
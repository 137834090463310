import {useParams} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api, TreeContext} from '../services';
import {useAuth, useTitle} from '../hooks';
import {Box, Breadcrumbs, Button, Chip, CircularProgress, Grid, Paper, Tab, Tooltip, Typography} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {ActiveAlerts, ConfirmDisassociateLubricator, LatestStatus, RefreshedAt} from '../components';
import FolderIcon from '@mui/icons-material/Folder';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SettingsIcon from '@mui/icons-material/Settings';
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';

function DetailsLubricator() {
    const {id} = useParams();
    const {setTitle} = useTitle();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext)
    const [tab, setTab] = useState('1');
    const [loading, setLoading] = useState(true);
    const [lubricator, setLubricator] = useState({});
    const {setShowConfirmDisassociate} = useContext(TreeContext);

    function loadLubricator() {
        setLoading(true);

        api.get('/lubricators/' + id)
            .then(response => setLubricator(response?.data?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 404) newAlert('O lubrificador solicitado não foi encontrado.');
                else if (code === 403) newAlert('Você não tem permissão para acessar os detalhes desse lubrificador.');
                else newAlert('Erro ao tentar carregar os detalhes desse lubrificador. Tente novamente mais tarde.');

                setLubricator({});
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Ver Lubrificador', 'Detalhes do Lubrificador #' + id), [id, setTitle]);

    // eslint-disable-next-line
    useEffect(loadLubricator, [id]);

    if (loading) return <CircularProgress className="m-auto"/>;

    if (!lubricator) return;

    return (
        <>
            <Box
                className="self-start"
                sx={{width: '100%'}}
            >
                <Box>
                    <Typography
                        variant="caption"
                        component="span"
                        className="font-bold"
                    >
                        {
                            lubricator.location ? 'Esse lubrificador está localizado em:' : 'Lubrificador não instalado'
                        }
                    </Typography>
                    <Box
                        role="presentation"
                        className="my-2"
                    >
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<NavigateNextIcon sx={{marginBottom: '12px'}}/>}
                        >
                            {
                                lubricator.location?.folders?.map((folder, i) => (
                                    <Chip
                                        icon={i === 0 ?
                                            <CorporateFareIcon sx={{mr: 0.5}} fontSize="small"/> :
                                            <FolderIcon sx={{mr: 0.5}} fontSize="small"/>
                                        }
                                        label={folder}
                                        className="mb-3"
                                        key={i}
                                    />
                                ))
                            }
                            {
                                lubricator?.location?.machine && (
                                    <Chip
                                        icon={<PrecisionManufacturingIcon sx={{mr: 0.5}} fontSize="small"/>}
                                        label={lubricator.location.machine}
                                        className="mb-3"
                                    />
                                )
                            }
                            {
                                lubricator?.location?.subset && (
                                    <Chip
                                        icon={<SettingsIcon sx={{mr: 0.5}} fontSize="small"/>}
                                        label={lubricator.location.subset}
                                        className="mb-3"
                                    />
                                )
                            }
                            {
                                lubricator?.location?.component && (
                                    <Chip
                                        icon={<RadioButtonCheckedIcon sx={{mr: 0.5}} fontSize="small"/>}
                                        label={lubricator.location.component}
                                        className="mb-3"
                                    />
                                )
                            }
                        </Breadcrumbs>
                    </Box>
                </Box>
                <RefreshedAt
                    className="my-1"
                    hour={lubricator.hour}
                    refresh={loadLubricator}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={4}>
                        <Paper className="p-2">
                            <Tooltip
                                title="ID"
                                className="flex items-center justify-center"
                            >
                                <Box>
                                    <NumbersOutlinedIcon className="me-2"/>
                                    {lubricator.id}
                                </Box>
                            </Tooltip>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={9} md={8}>
                        <Paper className="p-2 break-all">
                            <Tooltip
                                title="Número de Série"
                                className="flex items-center justify-center"
                            >
                                <Box>
                                    <AbcOutlinedIcon className="me-2"/>
                                    {lubricator.serial}
                                </Box>
                            </Tooltip>
                        </Paper>
                    </Grid>
                </Grid>
                <TabContext value={tab}>
                    <TabList
                        value={tab}
                        onChange={(event, newValue) => setTab(newValue)}
                        className="my-3"
                        aria-label="Detalhamento do Lubrificador"
                        centered
                    >
                        <Tab icon={<NotificationImportantOutlinedIcon/>} label="Alertas ativos" value="1"/>
                        <Tab icon={<HistoryOutlinedIcon/>} label="Últimos status" value="2"/>
                    </TabList>
                    <TabPanel value="1">
                        <ActiveAlerts activeAlerts={lubricator?.active_alerts}/>
                    </TabPanel>
                    <TabPanel
                        value="2"
                        className="text-center"
                    >
                        <LatestStatus lubricator={id}/>
                    </TabPanel>
                </TabContext>
            </Box>
            {
                lubricator.location && (
                    <>
                        <Box
                            className="self-end"
                            sx={{width: '100%'}}
                        >
                            <Button
                                fullWidth
                                startIcon={<LinkOffIcon/>}
                                variant="contained"
                                color="error"
                                type="submit"
                                onClick={() => setShowConfirmDisassociate(lubricator.id)}
                            >
                                Desassociar lubrificador
                            </Button>
                        </Box>
                        <ConfirmDisassociateLubricator callback={loadLubricator}/>
                    </>
                )
            }
        </>
    );
}

export default DetailsLubricator;

export {DetailsLubricator};
import {ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {useContext} from 'react';
import {TreeContext} from '../services';
import {AssociateLubricator, CreateOrEditAsset} from './';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import useAuth from '../hooks/useAuth';

function MenuNewAsset() {
    const {can} = useAuth();
    const {menu, setManageAsset, setMenu, setAssociateLubricator, setDuplicateAsset} = useContext(TreeContext);
    const {type, root, id} = menu?.data ?? {};
    const isRoot = type === 'folder' && root === parseInt(id);
    const canCreate = can('create_subfolders_assets', root);

    const handleClick = type => {
        setManageAsset({...menu?.data, variant: type});
        setMenu(false);
    }

    const handleAssociate = () => {
        setAssociateLubricator({...menu.data});
        setMenu(false);
    }

    const handleDuplicate = () => {
        setDuplicateAsset({...menu.data});
        setMenu(false);
    };

    return (
        <>
            <Menu
                id="menu-new-asset"
                anchorEl={menu?.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={menu?.type === 'create'}
                onClose={() => setMenu(false)}
            >
                {
                    (canCreate && !isRoot) && (
                        <MenuItem onClick={handleDuplicate}>
                            <ListItemIcon>
                                <ContentCopyIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Duplicar
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    canCreate && type === 'folder' && (
                        <MenuItem onClick={() => handleClick('folder')}>
                            <ListItemIcon>
                                <FolderIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Nova pasta
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    canCreate && type === 'folder' && (
                        <MenuItem onClick={() => handleClick('machine')}>
                            <ListItemIcon>
                                <PrecisionManufacturingIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Nova máquina
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    canCreate && type === 'machine' && (
                        <MenuItem onClick={() => handleClick('subset')}>
                            <ListItemIcon>
                                <SettingsIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Novo subconjunto
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    canCreate && type === 'subset' && (
                        <MenuItem onClick={() => handleClick('component')}>
                            <ListItemIcon>
                                <RadioButtonCheckedIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Novo componente
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    type !== 'folder' && can('install_lubricator_assets', root) && (
                        <MenuItem onClick={handleAssociate}>
                            <ListItemIcon>
                                <WaterDropIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Associar lubrificador
                            </ListItemText>
                        </MenuItem>
                    )
                }
            </Menu>
            <CreateOrEditAsset/>
            <AssociateLubricator/>
        </>
    );
}

export default MenuNewAsset;

export {MenuNewAsset};
import {api} from '../services';
import {useEffect, useState} from 'react';
import {Autocomplete, Grid, TextField, Typography} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

function FindUser({user, setUser}) {
    const [email, setEmail] = useState('');
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timeoutSearch, setTimeoutSearch] = useState(null);

    const handleDisableSubmit = event => event.keyCode === 13 && event.preventDefault();

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch);
        setTimeoutSearch(setTimeout(() => setEmail(search), 1000));
    };

    useEffect(() => {
        if (!email) {
            let newOptions = [];

            if (user) {
                newOptions = [user];
            }

            setOptions(newOptions);
            return undefined;
        }

        setLoading(true);

        api
            .get('/users/search', {
                params: {
                    email,
                }
            })
            .then(response => {
                let newOptions = [];

                if (response.data) {
                    newOptions = [...newOptions, ...response.data.data];
                }

                setOptions(newOptions);
            })
            .finally(() => setLoading(false));
    }, [user, email]);

    return (
        <Autocomplete
            autoComplete
            includeInputInList
            value={user}
            options={options}
            loading={loading}
            loadingText="Buscando ..."
            noOptionsText={email ? 'Nenhum usuário encontrado' : 'Digite o e-mail do usuário'}
            filterOptions={x => x}
            getOptionLabel={option => option.name}
            onInputChange={(event, value) => setSearch(value)}
            onChange={(event, value) => {
                setEmail('');
                setUser(value);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Usuário"
                    fullWidth
                    onKeyUp={handleTimeoutSearch}
                    onKeyDown={handleDisableSubmit}
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid container alignItems="center">
                        <Grid item sx={{display: 'flex', width: 44}}>
                            <PersonIcon/>
                        </Grid>
                        <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                            <Grid container direction="column">
                                <Grid item>
                                    {option.name}
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        {option.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </li>
            )}
        />
    );
}

export default FindUser;

export {FindUser};
import {Navigate, useLocation} from 'react-router-dom';
import {CircularProgress} from '@mui/material';
import {useAuth} from '../../hooks/useAuth';

function RequireAuth({children}) {
    let {loading, authenticated} = useAuth();
    const local = useLocation();

    if (loading) return <CircularProgress className="m-auto"/>;

    if (!authenticated) {
        return <Navigate to={`/login?redirect=${encodeURIComponent(local.pathname + local.search)}`}/>;
    }

    return children;
}

export default RequireAuth;

export {RequireAuth};
import {forwardRef, useContext} from 'react';
import {alpha, Box, Button, IconButton, styled, Tooltip, Typography, useTheme} from '@mui/material';
import {TreeItem, treeItemClasses} from '@mui/x-tree-view';
import {orange} from '@mui/material/colors';
import {TreeContext} from '../../services';
import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useAuth from '../../hooks/useAuth';

const red = '#FF5E5E';
const green = '#63FF55';

const StyledTreeItemRoot = styled(TreeItem)(({theme}) => ({
    color: theme.palette.text.primary,

    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.primary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),

        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 15,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

const StyledTreeItem = forwardRef((props, ref) => {
    let {
        icon: Icon,
        name,
        status,
        goTo,
        root,
        ...other
    } = props;

    const theme = useTheme();
    const {can} = useAuth();
    const [type, id] = props.nodeId.split('-');
    const largeScreen = theme.breakpoints.up('md');
    const iconColor = status === null ? orange[500] : status ? green : red;
    const {isConfiguring, setMenu, setShowConfirmDisassociate} = useContext(TreeContext);

    return (
        <StyledTreeItemRoot
            sx={{
                width: isConfiguring ? (largeScreen ? 350 : 320) : (largeScreen ? 300 : 270),
            }}
            label={
                <Box className="flex items-center">
                    <Box className="flex items-center p-0.5 pr-0">
                        <Box color="text.secondary" className="mr-1">
                            <Icon sx={{fontSize: '30px'}}/>
                        </Box>
                        <Typography variant="body2" className="grow flex items-center">
                            {name}
                        </Typography>
                    </Box>
                    {
                        !isConfiguring && status !== undefined && (
                            <Box
                                color={iconColor}
                                className="grow flex justify-end"
                            >
                                <CircleIcon
                                    className="m-1"
                                    sx={{fontSize: '30px'}}
                                />
                            </Box>
                        )
                    }
                    {
                        goTo && (
                            <IconButton
                                className="ms-auto"
                                color="primary"
                                size="small"
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'primary.main',
                                }}
                                onClick={() => goTo(id)}
                            >
                                <ArrowForwardIcon fontSize="inherit"/>
                            </IconButton>
                        )
                    }
                    {
                        isConfiguring && id !== '0' && (
                            <Box className="grow flex items-center justify-end">
                                {
                                    type !== 'lubricator' ? (
                                        <>
                                            <Tooltip title="Editar">
                                                <IconButton
                                                    className="p-1"
                                                    onClick={event => {
                                                        event.stopPropagation();

                                                        setMenu({
                                                            anchorEl: event.currentTarget,
                                                            data: {type, id, name, root},
                                                            type: 'edit',
                                                        });
                                                    }}
                                                >
                                                    <SettingsIcon fontSize="inherit"/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Criar">
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        width: 32,
                                                        height: 32,
                                                        padding: 0,
                                                        minWidth: 32,
                                                        borderRadius: '0 5px 5px 0',
                                                        boxShadow: 'none',
                                                        marginLeft: 1,
                                                    }}
                                                    onClick={event => {
                                                        event.stopPropagation();

                                                        setMenu({
                                                            anchorEl: event.currentTarget,
                                                            data: {type, id, root},
                                                            type: 'create',
                                                        });
                                                    }}
                                                >
                                                    <AddIcon/>
                                                </Button>
                                            </Tooltip>
                                        </>
                                    ) : (
                                        can('remove_lubricator_assets', root) && (
                                            <Tooltip title="Desassociar">
                                                <IconButton
                                                    className="p-1"
                                                    onClick={event => {
                                                        event.preventDefault();

                                                        setShowConfirmDisassociate(id);
                                                    }}
                                                >
                                                    <LinkOffIcon fontSize="inherit"/>
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    )
                                }
                            </Box>
                        )
                    }
                </Box>
            }
            {...other}
            ref={ref}
        />
    );
});

export default StyledTreeItem;

export {StyledTreeItem};
import {createContext} from 'react';
import useNotificationsProvider from '../hooks/useNotificationsProvider';

const NotificationsContext = createContext({});

function NotificationsProvider({children}) {
    const {
        loadingNotifications,
        loadNotifications,
        handleShowNotifications,
        handleCloseNotifications,
        notifications,
        showNotifications,
        handleClickNotification,
        readAll,
        page,
        setPage,
        perPage,
        setPerPage,
        request,
        setRequest,
        sendTokenToServer,
    } = useNotificationsProvider();

    return (
        <NotificationsContext.Provider value={{
            loadingNotifications,
            loadNotifications,
            handleShowNotifications,
            handleCloseNotifications,
            notifications,
            showNotifications,
            handleClickNotification,
            readAll,
            page,
            setPage,
            perPage,
            setPerPage,
            request,
            setRequest,
            sendTokenToServer,
        }}>
            {children}
        </NotificationsContext.Provider>
    );
}

export {NotificationsContext, NotificationsProvider};